'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import store from 'store';
import { Link } from 'react-router';

import TryItOutCard from './PatientRecommendations/TryItOutCard.react';
import TryItOutModal from './Modals/TryItOutModal.react';

import { getConfig } from '../../../utils/Env';
import AuthStore from '../../../stores/AuthStore';

import './TryItOut.scss';

export default class TryItOut extends Component {
    static contextTypes = {
        router: PropTypes.object,
        location: PropTypes.object,
        totalRecipes: PropTypes.number,
    };

    render() {
        const { totalRecipes } = this.context;
        const { showTryItOutModal} = this.props;

        return (
            <div className="dashboard-try-it-out">
                <h1>Quick Start Meal Plan</h1>
                {totalRecipes > 0 ?
                    <Link to="/search"><h2><em>{totalRecipes}</em> Recipes Available <i className="icon-arrow-right"></i> </h2></Link>
                : null}
                <div className="card-container">
                    <TryItOutCard onClick={showTryItOutModal} />
                    <button className="try-it-btn" onClick={showTryItOutModal}>Generate a Meal Plan</button>
                </div>

                <p className="subtext">For even more advanced personalization, start with creating a patient record first.
                <Link to="/clients?create=1">Create a patient record.</Link></p>
            </div>
        );
    }
}
