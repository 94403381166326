'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import DrawerMenu from './Widgets/DrawerMenu.react';
import ImgResized from '../../components/Widgets/ImgResized.react';

import AuthActions from '../../actions/AuthActions';
import UserStore from '../../stores/UserStore';
import { getConfig } from '../../utils/Env';

import './OffCanvas.scss';

export default class OffCanvas extends Component {

    static propTypes =  {
        showGrocery: PropTypes.bool,
    };

    static defaultProps = {
        showGrocery: false,
    };

    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            user: UserStore.getUser(),
            capabilities: UserStore.getCapabilities(),
        }
    }

    componentDidMount = () => {
        UserStore.addChangeListener(this.onUserStoreChange);
    }

    componentWillUnmount = () => {
        UserStore.removeChangeListener(this.onUserStoreChange);
    }

    onUserStoreChange = () => {
        this.setState({
            user: UserStore.getUser(),
            capabilities: UserStore.getCapabilities()
        });
    }

    logout = async () => {
        const { router } = this.context;

        await AuthActions.deauthorize();

        router.push('/');
    }

    renderUserImage = (user) => {
        let avatar = user.gender === 'male' ? <i className="icon-male3" /> : <i className="icon-female3" />;

        if (user.image) {
            return (
                <Link to="/my-account/settings">
                    <ImgResized src={user.image} width={200} height={200} className="profile-image" />
                </Link>
            )
        } else if (user.name) {
            const words = user.name.split(' ');
            // first letter of first word
            const firstInitial = (words[0] && words[0][0]) || '';
            // first letter of last word
            const lastInitial = (words.length > 1 && words[words.length - 1] && words[words.length - 1][0]) || '';

            avatar = (firstInitial || lastInitial) ? <em>{firstInitial}{lastInitial}</em> : avatar;
        }

        return (
            <Link to="/my-account/settings" className="avatar">{avatar}</Link>
        );
    }

    render = () => {
        const { user } = this.state;
        const { showGrocery } = this.props;

        if (!user) {
            return <span />
        }

        const wwwUrl = 'https://' + getConfig('www_host');

        return (
            <DrawerMenu button={<i className="icon-menu2" />}
                defaultClassName="side-menu-btn" modalClassName="side-menu-modal">
                <div className="side-navigation">
                    <header className="side-nav-header">
                        <div className='side-nav-profile'>
                            {this.renderUserImage(user)}
                            <Link to="/my-account/settings" className="side-nav-name">
                                <h6>{user.first_name || user.name || user.email}</h6>
                            </Link>
                            <button className="off-canvas-close">
                                <i className="icon-close-x" />
                            </button>
                        </div>
                    </header>


                    <ul className="side-nav-list">
                        <li><Link to="/my-account/settings">Account Settings</Link></li>
                        {user.dietitian && user.capabilities.purchase_membership ?
                            <li><Link to="/membership">Membership</Link></li>
                        : null}

                        <li><Link to="/invite">Rewards</Link></li>
                        {user.dietitian && user.dietitian.practice_role === 'admin' ?
                            <li><Link to="/team">Manage Team</Link></li>
                        : null}

                        {user ?
                            <li><a onClick={this.logout}>Sign out</a></li>
                        : null }
                    </ul>

                    <hr />

                    {
                    showGrocery && <div className="side-nav-list">
                        <header><Link to="/export-grocery">Grocery</Link></header>
                    </div>
                    }

                    <div className="side-nav-list">
                        <header><Link to="/favorites">Favorites</Link></header>
                    </div>
                    <div className="side-nav-list">
                        <header><Link to={{ pathname: "/favorites" , query: { types: "collection" } }}>Collections</Link></header>
                    </div>
                    <div className="side-nav-list">
                        <header><Link to="/search">Meal Search</Link></header>
                    </div>

                    { user.practice_type === "dietetics" ? <div className="side-nav-list">
                        <header><Link to="/?tryItOut=1">Meal Plan Generator</Link></header>
                    </div> : null }

                    <div className="side-nav-list">
                        <header><Link to="/nutrition-academy">Nutrition Academy</Link></header>
                    </div>

                    <div className="side-nav-list">
                        <header><Link to="/food-banks">Food Bank Finder</Link></header>
                    </div>

                    <hr />

                    <footer className="side-nav-footer">
                        <ul className="side-nav-list">
                            <header className="logo">EatLove</header>
                            <li><Link to="/client-tutorials">{user && user.practice_type === 'dietetics' ? 'Patient' : 'Client'} Tutorials</Link></li>
                            <li><a target="_blank" href={wwwUrl}>EatLove for {user && user.practice_type === 'dietetics' ? 'Patients' : 'Clients'}</a></li>
                            <li><a target="_blank" href="https://intercom.help/eatlovepro">FAQ</a></li>
                            <li><a href="mailto:support@eatlove.is">Contact Us</a></li>
                            <li><a target="_blank" href={wwwUrl + '/references'}>References</a></li>
                            <li><a target="_blank" href={wwwUrl + '/blog'}>Blog</a></li>
                            <li><a target="_blank" href={wwwUrl + '/about'} className="faq">About Us</a></li>
                            <li><a target="_blank" href={wwwUrl + '/privacy'}>Privacy Policy</a></li>
                            <li><a target="_blank" href={wwwUrl + '/terms'}>Terms of Use</a></li>
                        </ul>

                        <div className="off-canvas-social">
                            <div>
                                <a href="https://www.facebook.com/EatLove-470948183063093/timeline/" target="_blank">
                                    <i className="icon-facebook">
                                        <span className="assistive-text">Our Facebook Page</span>
                                    </i>
                                </a>
                            </div>
                            <div>
                                <a href="https://twitter.com/eatloveis" target="_blank">
                                    <i className="icon-twitter">
                                        <span className="assistive-text">Our Twitter Page</span>
                                    </i>
                                </a>
                            </div>
                            <div>
                                <a href="https://www.instagram.com/eatlove.pro/" target="_blank">
                                    <i className="icon-instagram">
                                        <span className="assistive-text">Our Instagram Page</span>
                                    </i>
                                </a>
                            </div>
                            <div>
                                <a href="https://www.pinterest.com/eatloveis/" target="_blank">
                                    <i className="icon-pinterest">
                                        <span className="assistive-text">Our Pinterest Page</span>
                                    </i>
                                </a>
                            </div>
                        </div>
                    </footer>
                </div>
            </DrawerMenu>
        );
    }
}
