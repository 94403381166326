'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import $ from 'jquery';

import CustomizerModal from './CustomizerModal.react';
import RecipeDetailsModal from '../../../components/Search/Modals/RecipeDetailsModal.react';
import CollectionEditorModal from '../../../components/Search/Modals/CollectionEditorModal.react';
import MealDetailsModal from '../../../pages/MealDetails.react';

import { getPrimaryMeal } from '../../../utils/Meals';

import '../../../components/Collections/Editor.scss';

export default class ContentModalWrapper extends Component {

    static propTypes = {
        profile: PropTypes.object,
    };

    static contextTypes = {
        router: PropTypes.object,
        location: PropTypes.object,
    };

    static childContextTypes = {
        showCustomizer: PropTypes.func,
        showPatientCustomizer: PropTypes.func,
        showRecipeDetails: PropTypes.func,
        showMealDetails: PropTypes.func,
        showCollectionDetails: PropTypes.func,
        replaceMealPlan: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            patient: null,
        };
    }

    getChildContext = () => {
        return {
            showCustomizer: this.showCustomizer,
            showPatientCustomizer: this.showPatientCustomizer,
            showRecipeDetails: this.showRecipeDetails,
            showMealDetails: this.showMealDetails,
            showCollectionDetails: this.showCollectionDetails,
            replaceMealPlan: this.replaceMealPlan,
        };
    }

    replaceMealPlan = (oldPlan, newPlan) => {
        const { router, location } = this.context;
        const { pathname, query, hash } = location;

        query.pid = newPlan.uuid;
        router.push({pathname, query, hash});
    }

    showCustomizer = (plan) => {
        const { router, location } = this.context;
        const { pathname, query, hash } = location;

        if (!(plan.links && plan.links.self)) {
            query.virtual = 1;
        }

        query.pid = plan.uuid;
        router.push({pathname, query, hash});
    }

    showPatientCustomizer = (patient, plan) => {
        const { router, location } = this.context;
        const { pathname, query, hash } = location;

        if (!(plan.links && plan.links.self)) {
            query.virtual = 1;
        }

        query.pid = plan.uuid;
        router.push({pathname, query, hash});

        this.setState({patient});
    }

    showRecipeDetails = (recipe) => {
        const { router, location } = this.context;
        const { pathname, query, hash } = location;

        query.rid = recipe.uuid;
        router.push({pathname, query, hash});
    }

    showMealDetails = (meals) => {
        const { router, location } = this.context;
        const { pathname, query, hash } = location;
        const { primary } = getPrimaryMeal(meals);

        if (!primary) {
            return;
        }

        query.mealType = primary.meal;
        query.date = primary.date;

        router.push({pathname, query, hash});
    }

    showCollectionDetails = (collection) => {
        const { router, location } = this.context;
        const { pathname, query, hash } = location;

        query.clid = collection.uuid;
        router.push({pathname, query, hash});
    }

    closeModal = (completedModalAction) => {
        const { onCompleteAction } = this.props;
        const { router, location } = this.context;
        const { pathname, query, hash } = location;

        delete query.pid;
        delete query.rid;
        delete query.virtual;
        delete query.sendToPatient;
        delete query.swapRecipe;
        delete query.addRecipe;
        delete query.mealDetails;
        delete query.sendToPatient;
        delete query.showGroceries;
        delete query.showNutrition;
        delete query.pickDate;
        delete query.mealType;
        delete query.date;
        delete query.clid;

        router.push({pathname, query, hash});

        $('body').removeClass('ReactModal__Body--open').removeAttr('aria-hidden');

        if (completedModalAction === true && onCompleteAction) {
            onCompleteAction();
        }

        this.setState({patient: null});
    }

    renderCustomizerModal = () => {
        const { location } = this.context;
        const { patient } = this.state;
        const { profile, preferences, recommendations, prescriptions, showPdfPrint, editableParticipants } = this.props;

        if (!location.query.pid) {
            return;
        }

        return (
            <CustomizerModal uuid={location.query.pid}
                closeModal={this.closeModal}
                patient={patient || profile}
                editableParticipants={editableParticipants}
                showPdfPrint={showPdfPrint}
                preferences={preferences}
                recommendations={recommendations}
                prescriptions={prescriptions}
                location={location} />
        );
    }

    renderRecipeDetailsModal = () => {
        const { location } = this.context;
        const { profile, editableParticipants } = this.props;

        if (!location.query.rid) {
            return;
        }

        return <RecipeDetailsModal uuid={location.query.rid}
                    closeModal={this.closeModal}
                    profile={profile}
                    editableParticipants={editableParticipants} />
    }

    renderMealDetails = () => {
        const { location } = this.context;
        const { profile, meals } = this.props;

        const { mealType, date } = location.query;

        if (!mealType || !date) {
            return;
        }

        return <MealDetailsModal params={{mealType, date}}
            hidePrintBtn={true}
            hideEditBtns={true}
            profile={profile}
            closeModal={this.closeModal} />
    }

    renderCollectionDetails = () => {
        const { location } = this.context;
        const { profile, meals } = this.props;

        const { clid } = location.query;

        if (!clid) {
            return;
        }

        return <CollectionEditorModal uuid={clid} closeModal={this.closeModal} />
    }


    render() {
        return (
            <div>
                {this.props.children}
                {this.renderCustomizerModal()}
                {this.renderRecipeDetailsModal()}
                {this.renderMealDetails()}
                {this.renderCollectionDetails()}
            </div>
        );
    }
}
