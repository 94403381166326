'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Dropdown from '../../Widgets/Dropdown.react';

import './TryItOutCard.scss';

export default class TryItOutCard extends Component {
    render() {
        const { onClick } = this.props;

        return (
            <div onClick={onClick} className="plan-card try-it-out-card" data-advanced={false} data-short={false}>
                <div className="plan-card-fan1"></div>
                <div className="plan-card-fan2"></div>
                <div className="plan-card-image-container">
                    <div className="plan-card-image"></div>
                    <div className="plan-card-contents">
                        <div className="plan-card-text">
                            <div>
                                <i className="icon-logo2"><span className="path1" /><span className="path2" /></i>
                                <h3>Create a starter plan for your client in seconds!</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
