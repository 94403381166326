'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';
import LocalStorage from 'store';

import PatientCard from '../Patients/PatientCard.react';
import NotNowModal from './Modals/NotNowModal.react';

import UserStore from '../../../stores/UserStore';
import AuthStore from '../../../stores/AuthStore';
import ChatStore from '../../../stores/ChatStore';

import { getConfig } from '../../../utils/Env';
import { isPatientHidden } from '../../utils/Patients';

import './NeedsUpdate.scss';
import '../Widgets/Scroller.scss';

export default class NeedsUpdate extends Component {
    static contextTypes = {
        user: PropTypes.object,
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            patients: [],
            loading: true,
            appending: false,
            unreads: ChatStore.getUnreads(),
            unreadHidden: this.areUnreadMessagesHidden(context.user),

            notNowPatient: null,
            reviewedCount: null,
            runDate: null,
        };
    }

    componentDidMount = () => {
        this.loadPatients();
        ChatStore.addChangeListener(this.onChatStoreChange);
        this.searchCompletedReviews();
    }

    componentWillUnmount = () => {
        ChatStore.removeChangeListener(this.onChatStoreChange);
    }

    onChatStoreChange = () => {
        const unreads = ChatStore.getUnreads();

        this.setState({unreads});
    }

    showNotNow = (notNowPatient) => {
        this.setState({notNowPatient});
    }

    closeNotNowModal = () => {
        this.setState({notNowPatient: null});
    }

    loadPatients = (append = false) => {
        const user = UserStore.getUser();

        const query = {
            sort: user.practice_type === 'dietetics' ? 'last_recommendation_date' : 'invite_sent',
            following: 'current',
            order: 'asc',
            size: 5,
        };

        if (append) {
            query.from = this.patients.length;
        }

        return AuthStore.fetch({url: getConfig('users_api') + user.links.patients.href, query}).then(
            response => {
                const newPatients = append ? this.state.patients.concat(response.elements) : response.elements;

                this.setState({
                    patients: newPatients,
                    total: response.total,
                    loading: false,
                    appending: false,
                });

                return newPatients;
            },
            error => error,
        );
    }

    renderPatient = (patient, key) => {
        // Has this patient been "Not Now'd?"
        if (isPatientHidden(patient)) {
            return null;
        }

        const { user } = this.context;

        // prevent logout issues from the dashboard.
        if (!user) {
            return;
        }

        return (
            <li key={key} className="card-container">
                <PatientCard patient={patient} hideFollowing={true} showSendPlan={user.practice_type === 'dietetics'} />

                <button className="hide-btn" onClick={() => this.showNotNow(patient)}><i className="icon-close-x" /></button>
            </li>
        );
    }

    renderNotNowModal = () => {
        const { notNowPatient } = this.state;

        if (!notNowPatient) {
            return;
        }

        return <NotNowModal patient={notNowPatient} closeModal={this.closeNotNowModal} />
    }

    hideUnreadMessages = () => {
        const { user } = this.context;

        if (!user) {
            return;
        }

        const expires = moment().add(1, 'day').format();
        const key = `unread-messages-${user.uuid}`;

        LocalStorage.set(key, {expires});
        this.setState({unreadHidden: true});
    }

    areUnreadMessagesHidden = (user) => {
        if (!user) {
            return false;
        }

        const key = `unread-messages-${user.uuid}`;

        let { expires } = LocalStorage.get(key) || {};

        if (!expires || (expires && moment().isAfter(expires))) {
            LocalStorage.remove(key);

            return false;
        }

        return true;
    }

    checkToDeleteUnreadToken = (latestUnreadMessageThread) => {
        const { user } = this.context;

        if (!user) {
            return;
        }

        const key = `unread-messages-${user.uuid}`;

        let { expires } = LocalStorage.get(key) || {};

        if (!expires) {
            return;
        }

        const latestTimestamp = latestUnreadMessageThread.messages[latestUnreadMessageThread.messages.length - 1].timestamp;

        if ( moment(latestTimestamp) > moment(expires).subtract(1, 'days')) {
            LocalStorage.remove(key);
        }

    }

    searchCompletedReviews = async () => {
        const { user } = this.context;

        const runDay = user?.features?.auto_populate_groceries?.run_day;

        if (!runDay) {
            return;
        }

        const runDate = this.getLastRunDate(runDay);

        const groceryReviewQuery = {
            eventType: "Submit Grocery Review",
            startDate: moment(runDate).unix(),
            endDate: moment().unix(),
            size: 500
        }

        const groceryUpdateQuery = {
            eventType: "Grocery List Updated",
            startDate: moment(runDate).unix(),
            endDate: moment().unix(),
            size: 500
        }

        const groceryReviewActivity = await AuthStore.fetch({ url: getConfig('users_api') + user.links.activity.href, query: groceryReviewQuery });

        const groceryUpdateActivity =  await AuthStore.fetch({ url: getConfig('users_api') + user.links.activity.href, query: groceryUpdateQuery });
        
        const upToDateReviews = groceryReviewActivity.elements.filter(activity => {
            const updatedList = groceryUpdateActivity.elements.find(obj => obj.patient.uuid === obj.patient.uuid);
            return updatedList ? activity.created > updatedList.created : true;
        });

        this.setState({
            reviewedCount: upToDateReviews.length,
        });
    }

    getLastRunDate = (runDay) => {
        const today = moment();
        const targetDay = moment().day(runDay);
        while (targetDay.isAfter(today)) {
            targetDay.subtract(7, 'days');
        }

        const latestDate = targetDay.format('YYYY-MM-DD');

        this.setState({runDate: latestDate});

        return latestDate;
    }

    render() {
        const { patients, unreads, unreadHidden, reviewedCount, runDate } = this.state;

        const showGroceryAlert = runDate;

        const weekStart = runDate ? moment(runDate).format('Do MMMM') : null;

        if (patients.length <= 0) {
            return <span />
        }

        const latestUnreadMessageThread = ChatStore.getLatestUnread();

        if (latestUnreadMessageThread && unreadHidden) {
            this.checkToDeleteUnreadToken(latestUnreadMessageThread);
        }

        return (
            <div className="dashboard-needs-update">
                <header>
                    <h2>Alerts</h2>
                </header>

                {patients.length ?
                    <ul>
                        {
                            showGroceryAlert && 
                            <div className="patient-card alert-card grocery-alert-card">
                                <Link to={`/export-grocery`} onClick={(e) => e.stopPropagation()}>
                                    <i className="avatar icon-groceries" />
                                    <div className="alert-info">
                                        <h4>Grocery List</h4>

                                        <div className="alert-subtext">
                                        <div className='grocery-list-date'>
                                            Week of <span style={{ fontWeight: '700' }}>{weekStart}</span>
                                        </div>
                                        <div className='reviews-info'>
                                           <span>{reviewedCount}/{patients.length}</span> reviews complete
                                        </div>
                                        </div>
                                        <div>
                                            <button className="manage-grocery-btn">MANAGE GROCERY LIST</button>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        }
                        { !unreadHidden && unreads.length ? (
                            <li className="card-container">
                                <div className="patient-card alert-card unread-message-card">
                                    <Link to={`/messages`} onClick={(e) => e.stopPropagation()}>
                                        <i className="icon-messages" />
                                        <div className="alert-info">
                                            <h4>You have unread messages from:</h4>

                                            <div className="alert-subtext">
                                                {unreads.map((chat, i) => <Link key={i} to={`/messages/${chat.rosterItem.jid}`}> <p className="message-sender">{chat.rosterItem.nick}</p> </Link>)}
                                            </div>
                                            <div>
                                                <button className="view-messages-btn">VIEW UNREAD MESSAGES</button>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <button className="hide-btn" onClick={this.hideUnreadMessages}><i className="icon-close-x" /></button>
                            </li>                            

                        )
                        : null}
                        {patients.map(this.renderPatient)}
                        
                    </ul>
                : null}

                {this.renderNotNowModal()}
            </div>
        );
    }
}
