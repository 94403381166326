'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import ImgResized from '../../Widgets/ImgResized.react';
import FavoriteButton from '../../Widgets/FavoriteButton.react';
import PublisherSubmenu from '../../Publisher/Dashboard/PublisherSubmenu.react';
import { toHuman } from '../../../utils/Duration';
import './RecipeCard.scss';

export default class RecipeCard extends Component {
    static propTypes = {
        recipe: PropTypes.object,
        alreadyInCollection: PropTypes.bool,
        isLogging: PropTypes.bool,
        onClickRecipeCard: PropTypes.func
    };

    static defaultProps = {
        alreadyInCollection: false,
    };

    static contextTypes = {
        isPublisher: PropTypes.bool,
        showResultsFavoriteBtn: PropTypes.bool,
        onSelectRecipe: PropTypes.func,
        showRecipeDetails: PropTypes.func,
    };

    onClick = (ev) => {
        const { onSelectRecipe, showRecipeDetails } = this.context;
        const { recipe, isLogging, onClickRecipeCard, organicRanking, searchRanking, searchTerm, sortBy, sortOrder, source } = this.props;

        if (ev.button == 1 || ev.ctrlKey) {
            return;
        }

        ev.preventDefault();

        if (onClickRecipeCard) {
            onClickRecipeCard();
            return;
        }

        if (onSelectRecipe && isLogging) {
            onSelectRecipe(recipe, null, undefined, undefined, {organicRanking, searchRanking, term: searchTerm, sortBy, sortOrder, source});
        } else if (showRecipeDetails) {
            showRecipeDetails(recipe, {organicRanking, searchRanking, term: searchTerm, sortBy, sortOrder, source});
        }
    }

    link = (element) => {
        const { isPublisher } = this.context;
        const { recipe } = this.props;

        if (isPublisher) {
            return <Link to={`/publisher/recipe/${recipe.uuid}`}>{element}</Link>;
        }

        return <Link to={`/recipes/${recipe.uuid}`} onClick={this.onClick}>{element}</Link>
    }

    render() {
        const { isPublisher, showResultsFavoriteBtn } = this.context;
        const { recipe, alreadyInCollection } = this.props;

        const imageProps = {};

        if (!recipe) {
            return <span />
        }

        if (recipe.image_thumb) {
            imageProps.style = {
                backgroundImage: 'url(data:image/jpeg;base64,' + recipe.image_thumb + ')',
            };

            imageProps['data-has-thumb'] = true;
        }

        return (
            this.link(
                <div className="recipe-card" data-in-collection={alreadyInCollection}>
                    <ImgResized className="recipe-card-image" src={recipe.image} width={300} height={300} {...imageProps} />

                    {(recipe.tags || []).includes('Grab & Go') ?
                        <div className="image-overlay">Store Bought</div>
                    : null}

                    <div className="recipe-card-text">
                        <h3>{recipe.title}</h3>
                        {recipe.merchant ? <h4>{recipe.merchant.name}</h4> : null}
                        {recipe.total_time ? <h5><i className="icon-clock1" /> {toHuman(Math.round(recipe.total_time / 60) * 60, true)}</h5> : null}
                        {isPublisher ?
                            <div className={recipe.status === 'live' ? 'publish-status live' : 'publish-status'}>
                                {recipe.status === 'live' ? 'live' : 'draft'}
                            </div>
                        : null}
                    </div>

                    {isPublisher ?
                        <PublisherSubmenu resource={recipe} />
                    : null}

                    {showResultsFavoriteBtn && !alreadyInCollection ?
                        <FavoriteButton recipe={recipe} />
                    : null}
                </div>
            )
        );
    }
}
