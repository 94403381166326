'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import moment from 'moment';
import store from 'store';

import Analytics from '../../utils/Analytics';
import AvoidanceSelect from '../Preferences/AvoidanceSelect.react';

import modalStyles from '../../jsx-styles/modals';
import '../../pro/components/Modals/Modals.scss';
import '../../pro/components/Patients/Editor/MultiSelect.scss';

import '../MyAccount/PreferencesForm.scss';
import './AvoidancesModal.scss';

export default class AvoidancesModal extends Component {

    onChangeProfile = (profile) => {
        const { diets, avoidances, exclude_foods } = profile.preferences;
        const { setAvoidances } = this.props;

        setAvoidances(diets, avoidances, exclude_foods);
    }

    render() {
        const { profile, closeModal, tryItMode } = this.props;

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                contentLabel="Food Avoidances"
                className="el-modal el-modal2 avoidances-modal"
                style={modalStyles.largeSquareModal}
                closeTimeoutMS={250}>
                <div className="el-modal-container el-modal2-container avoidances-modal-container">
                    <header className="modal-header">
                        <h2>Food Avoidances</h2>
                        <button className="el-modal-close-x" onClick={closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>
                    <div className="el-modal-body-container el-modal2-body-container el-fonts">
                        <div className="preferences-form prefs-avoidances">
                            <AvoidanceSelect profile={profile} onChangeProfile={this.onChangeProfile} />
                        </div>
                    </div>
                    <footer>
                        <button className="el-modal-ok-btn" onClick={closeModal}>Done</button>
                    </footer>

                </div>
            </Modal>
        );
    }
}
