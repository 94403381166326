'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AboutYourPatient from '../AboutYourPatient.react';
import EditorModal from '../../../Modals/EditorModal.react';
import AuthStore from '../../../../../stores/AuthStore';
import { getConfig } from '../../../../../utils/Env';
import Analytics from '../../../../../utils/Analytics';
import { titleCase } from '../../../../../utils/TextUtil';
import { isSameValue } from '../../../../../utils/Util';


export default class PatientMetaEditorModal extends Component {
    static propTypes = {
        patient: PropTypes.object,
        closeModal: PropTypes.func,
        onChangePatient: PropTypes.func,
        hidePatientOnExpirationChange: PropTypes.bool,
    };

    static contextTypes = {
        confirm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.editor = React.createRef();

        this.state = {
            working: false,
        };
    }

    savePatient = () => {
        let { patient, closeModal, onChangePatient } = this.props;

        if (!this.editor.current.isDirty()) {
            return this.closeModal();
        }

        const error = this.editor.current.validate(true);

        // Validate the form browser-side first.
        if (error !== true) {
            this.setState({ error });
            return;
        }

        const originalPatient = { ...patient };

        // Mutate the patient record, we're finished
        patient = this.editor.current.mutate(patient, true);

        const biometricsUpdated = [
            'gender',
            'gender_identity',
            'birthdate',
            'height_cm',
            'weight_kg',
            'patient_number',
            'units_mode',
            'conditions',
        ].filter((key) => !isSameValue(originalPatient[key], patient[key]));

        // Omit the stuff from the patient record that's read-only. E.g.: we can't change the uuid
        const {
            uuid,
            links,
            created,
            conditions,
            preferences,
            prescriptions,
            invite_sent,
            invite_accepted,
            recommendations,
            active,
            activated_date,
            deactivated_date,
            ...rest
        } = patient;

        this.setState({ working: true, error: null });

        // Save the record
        AuthStore.fetch(getConfig('users_api') + patient.links.self.href, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; schema=patient/1' },
            body: JSON.stringify({ ...rest }),
        }).then(
            (response) => {
                // Notify the parent that there's been a change and
                onChangePatient(response);

                closeModal();

                Analytics.updatePatientBiometrics({
                    'Patient UUID': patient.uuid,
                    Biometrics: biometricsUpdated.map((field) => titleCase(field)),
                });
            },
            (error) => {
                this.setState({ working: false, error: error.message });
            }
        );
    };

    closeModal = (page) => {
        const { closeModal } = this.props;

        if (this.editor.current.isDirty()) {
            this.context.confirm(
                <div className="confirm-discard-changes">
                    <h6>Discard changes?</h6>
                    <p>You&apos;ve made changes to this patient, are you sure you want to discard those changes?</p>
                </div>,
                closeModal,
                () => false,
                {
                    acceptText: 'Discard Changes',
                }
            );

            return;
        }

        closeModal();
    };

    render() {
        const { patient, hidePatientOnExpirationChange } = this.props;
        const { error, working } = this.state;

        return (
            <EditorModal
                title={`About ${patient.first_name}`}
                error={error}
                closeModal={this.closeModal}
                working={working}
                patient={patient}
                onSavePatient={this.savePatient}
            >
                <AboutYourPatient
                    patient={patient}
                    hidePatientOnExpirationChange={hidePatientOnExpirationChange}
                    ref={this.editor}
                />
            </EditorModal>
        );
    }
}
