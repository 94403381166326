'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import PatientDeactivator from '../../components/Patients/PatientDeactivator.react';


import './Activation.scss';

export default class Activation extends Component {
    static propTypes = {
        patient: PropTypes.object,
        toggleFollowing: PropTypes.func,
        onChangePatient: PropTypes.func,
    };

    render = () => {
        const {patient, toggleFollowing, onChangePatient } = this.props;

        return (
            <div className="activation" data-patient-active={patient.active}>
                <button className="follow-toggle-btn"  disabled={!patient.active} onClick={toggleFollowing}>
                    <span className="toggle" data-following={patient.following && patient.active ? true : false}></span>
                    <span>{patient.following && patient.active ? "Following" : "Not Following"}</span>
                </button>

                <p>
                    {patient.following && patient.active ? `You will receive alerts and feed notifications about ${patient.first_name}`
                    : `Alerts and feed notifications about ${patient.first_name} have been turned off.`}
                </p>

                <PatientDeactivator patient={patient} onChangePatient={onChangePatient} />

            </div>

        );
    }
}
