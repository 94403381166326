'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';
import ImgResized from '../../Widgets/ImgResized.react';

import MealTypes from '../../../constants/MealTypes';
import { getPrimaryMeal } from '../../../utils/Meals';

import './MealDraggable.scss';

@DropTarget(
    MealTypes.MEAL,
    {
        hover(props, monitor) {
            var item = monitor.getItem();

            if (item.meal != props.meal) {
                props.moveMeal(item.meal, props.meal);
            }
        }
    },
    (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
    })
)
@DragSource(
    MealTypes.MEAL,
    {
        beginDrag(props) {
            return { meal: props.meal };
        },
        endDrag(props, monitor, component) {
            props.onModifyMeals([props.meal]);
        },
        isDragging(props, monitor) {
            return props.meal === monitor.getItem().meal;
        },
        canDrag(props) {
            return !props.disableDnD;
        }
    },
    (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    })
)
export default class MealDraggable extends Component {
    static propTypes = {
        meal: PropTypes.object,
        disableDnD: PropTypes.bool,
        disableControls: PropTypes.bool,
        deleteMeal: PropTypes.func,
    };

    static defaultProps = {
        disableDnD: false,
        disableControls: false,
    };

    static contextTypes = {
        recipes: PropTypes.object,
        foods: PropTypes.object,

        confirm: PropTypes.func,
        onModifyMeals: PropTypes.func,
        showMealDetails: PropTypes.func,
    };

    undoDelete = (event) => {
        const { meal } = this.props;
        const { onModifyMeals } = this.context;
        event.stopPropagation();

        onModifyMeals([meal]);
    }

    deleteMeal = (event, meal, content) => {
        const { confirm } = this.context;
        const { deleteMeal } = this.props;
        event.stopPropagation();

        confirm(
            `Are you sure you want to remove ${content.title} from this collection?`,
            accept => deleteMeal(meal),
            reject => false
        );
    }

    renderControls = (primary, content) => {

        if (primary.deleted) {
            return (
                <footer className="card-controls">
                    <button onClick={(ev) => this.undoDelete(ev)} className="undo-delete">undo delete</button>
                </footer>
            );
        }

        const controlBtns = [
            <button key="delete" className="delete-item-btn" onClick={(ev) => this.deleteMeal(ev, primary, content)}><i className="icon-trash-can4" /></button>
        ];

        return (
            <footer className="card-controls">
                {controlBtns}
            </footer>
        );
    }

    renderMealInfo = (primary, titles) => {
        const { meal } = this.props;

        if (primary.deleted) {
            return;
        }

        return (
            <div className="meal-info">
                <p>{titles.join(' + ')}</p>
            </div>
        );
    }

    render() {
        const { recipes, foods } = this.context;
        const { meal, disableDnD, disableControls, isDragging, connectDragSource, connectDropTarget } = this.props;
        const { isMobile, showMealDetails } = this.context;

        const { content, titles, photos } = getPrimaryMeal([meal], recipes, foods);

        // If we don't have a primary meal, don't render anything.
        if (!content) {
            return <span />
        }

        let imageProps = {width: 300, height: 190}, showCardAsImage = false;

        const setCardImage = (cardImage) => {
            imageProps.src = cardImage;
        }

        if (photos[0]) {
            setCardImage(photos[0].url);
            showCardAsImage = true;
        }

        return connectDropTarget(connectDragSource(
            <div data-dragging={isDragging}
                data-dnd-disabled={disableDnD}
                data-is-mobile={isMobile}
                data-is-logged={false}
                data-meal-type={meal.meal_type}
                className="grid-meal-draggable collection-meal-draggable" title={content.title}>
                <div className="image-container">
                    {showCardAsImage
                        ? <ImgResized onClick={() => showMealDetails([meal])} className="meal-image" {...imageProps} />
                        : <div onClick={() => showMealDetails([meal])} className="meal-image" {...imageProps}></div>}
                </div>
                <div onClick={() => showMealDetails([meal])} className="controls-container">
                    {!disableControls ? this.renderControls(meal, content) : null}
                    {this.renderMealInfo(meal, titles)}
                </div>
            </div>
        ));
    }
}
