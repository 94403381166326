'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import indexBy from 'lodash.indexby';

import UserStore from '../../../../stores/UserStore';
import allNutrients from '../../../../tables/nutrients';
import Dropdown from '../../Widgets/Dropdown.react';

import './PrescriptionMismatch.scss';

export default class PrescriptionMismatch extends Component {
    static propTypes = {
    };

    renderMismatch = (mismatch, key) => {
        const nutrient = allNutrients[mismatch.nutrNo];

        if (!nutrient) {
            return;
        }

        const { value, min, max } = mismatch;

        if (value < min) {
            return (
                <li key={key}>
                    {Math.round(min - value)} {nutrient.Units} <em>{nutrient.NutrDesc}</em> too little
                </li>
            );
        }

        if (value > max) {
            return (
                <li key={key}>
                    {Math.round(value - max)} {nutrient.Units} <em>{nutrient.NutrDesc}</em> too much
                </li>
            );
        }

        return null;
    }

    render() {
        const { mismatches } = this.props;

        const user = UserStore.getUser() || {};

        const button = (
            <div className="prescription-mismatch-btn">
                <p><i className="icon-warning" /> Does not match {user.practice_type === 'dietetics' ? 'patient\'s nutrition prescription' : 'client\'s nutrient profile'}</p>
            </div>
        );

        const indexedMismatches = {
            'All Day': mismatches.filter(m => m.meal_type === 'all-day'),
            'Breakfast': mismatches.filter(m => m.meal_type === 'Breakfast'),
            'Lunch': mismatches.filter(m => m.meal_type === 'Lunch'),
            'Dinner': mismatches.filter(m => m.meal_type === 'Dinner'),
            'Snack': mismatches.filter(m => m.meal_type === 'Snack'),
        };

        return (
            <div className="prescription-mismatch-container">
                <Dropdown button={button}>
                    <div className="prescription-mismatch-list">
                        {Object.keys(indexedMismatches).map(mealType => {
                            if (indexedMismatches[mealType].length == 0) {
                                return null
                            }

                            return (
                                <div className="prescription-mismatch-part" key={mealType}>
                                    <p>{mealType}</p>

                                    <ul>
                                        {indexedMismatches[mealType].map(this.renderMismatch)}
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                </Dropdown>
            </div>
        );
    }
}
