'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';

import Working from '../Working.react';
import AuthStore from '../../../../../stores/AuthStore';
import { getConfig } from '../../../../../utils/Env';

import './AddVisitModal.scss';
import modalStyles from '../../../../../jsx-styles/modals';

export default class AddVisitModal extends Component {
    static propTypes = {
    };

    constructor(props) {
        super(props);

        this.state = {
            working: false,
            body: '',
            provider_visit_id: '',
        };
    }

    focused = null;

    autofocus = (el) => {
        if (el && !this.focused) {
            this.focused = el;
            this.focused.focus();
        }

        // Component unmounted. Goodbye.
        if (!el) {
            this.focused = null;
        }
    }

    onAddVisit = () => {
        const { body, provider_visit_id } = this.state;
        const { patient, onAddVisit } = this.props;

        this.setState({working: true});

        AuthStore.fetch(getConfig('users_api') + patient.links.visits.href, {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=visit/1'},
            body: JSON.stringify({body, provider_visit_id}),
        }).then(
            response => {
                onAddVisit(response);
            },
            error => {
                this.setState({working: false, error: error.message});
            }
        );
    }

    render() {
        const { working, body, provider_visit_id } = this.state;
        const { closeModal } = this.props;

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                className="add-remove-nutrients-modal"
                contentLabel="Add Visit Note"
                style={modalStyles.largeSquareModal}
                closeTimeoutMS={250}>

                <div className="add-remove-nutrients-container editor-modal-container add-visit-container">
                    {!working ?
                        <header className="modal-header">
                            <h2>Add Visit Note</h2>
                            <button className="close-btn" onClick={closeModal}>
                                <i className="icon-close-x" />
                                <span className="assistive-text">Close Modal</span>
                            </button>
                        </header>
                    : null}

                    {!working ?
                        <div className="visit-notes">
                            <textarea type="text" placeholder="Visit Notes"
                                ref={this.autofocus}
                                value={body}
                                onChange={ev => this.setState({body: ev.target.value})} />
                        </div>
                    : null}

                    <div className="visit-provider-id">
                        <input type="text" value={provider_visit_id}
                            onChange={ev => this.setState({provider_visit_id: ev.target.value})}
                            placeholder="Visit ID (optional)" />
                    </div>

                    {!working ?
                        <footer>
                            <button className="cancel" onClick={closeModal}>Cancel</button>
                            <button className="accept" onClick={this.onAddVisit}>Add Visit Note</button>
                        </footer>
                    : null}

                    {working ? <Working title="Saving visit..." message="" /> : null}

                </div>
            </Modal>
        );
    }
}
