'use strict';

import { Component } from 'react';
import Modal from 'react-modal';

import './SendInviteModal.scss';
import './Modals.scss';
import modalStyles from '../../../jsx-styles/modals';

export default class SendInviteModal extends Component {
    static propTypes = {
    };

    constructor(props) {
        super(props);

        this.state = {
            patients: (props.patients || []).slice(0), // Make a duplicate of the recipients
            message: '',
            sendEmail: true,
        };
    }

    removePatient = (patient) => {
        const { patients } = this.state;

        patients.splice(patients.indexOf(patient), 1);

        this.setState({patients});
    }

    recommendPlan = () => {
        const { patients, message, sendEmail } = this.state;
        const { plan, onRecommendPlan } = this.props;

        onRecommendPlan(plan, patients, message, sendEmail);
    }

    render() {
        const { error, closeModal, working } = this.props;

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                className="info-modal send-invite-modal"
                contentLabel="Edit Patient"
                style={modalStyles.largeSquareModal}
                closeTimeoutMS={250}>

                <div className="modal-container send-invite-modal-container">
                    {!working ?
                        <header className="modal-header">
                            <button className="close-btn" onClick={closeModal}>
                                <i className="icon-close-x" />
                                <span className="assistive-text">Close Modal</span>
                            </button>
                        </header>
                    : null}

                    {!working ?
                        <div className="modal-scroll-container">
                            <div className="modal-body">
                                <h2>{error ? error : 'Invite has been sent'}</h2>
                                <i className="icon-email" />
                            </div>

                            <footer className="modal-action-footer">
                                <button className="ok-btn" onClick={closeModal}>Ok</button>
                            </footer>
                        </div>
                    : null}

                    {working ?
                        <div className="modal-scroll-container">
                            <div className="modal-body">
                                <h2>Sending Invite</h2>
                                <i className="icon-spinner" />
                                <p>Your invitation is being sent.</p>
                            </div>
                        </div>
                    : null}
                </div>
            </Modal>
        );
    }
}
