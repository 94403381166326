'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';

import Working from '../Patients/Editor/Working.react';

import './Modals.scss';
import modalStyles from '../../../jsx-styles/modals';

export default class EditorModal extends Component {
    static propTypes = {
        workingTitle: PropTypes.string,
        workingMessage: PropTypes.string,

        acceptText: PropTypes.string,
        rejectText: PropTypes.string,
    };

    static defaultProps = {
        workingTitle: "Saving patient...",
        workingMessage: "",

        acceptText: "Save",
        rejectText: "Cancel"
    };

    scrollable = null;

    realizeScrollable = (el) => {
        this.scrollable = el;
    }

    scrollTop = (top) => {
        if (this.scrollable) {
            this.scrollable.scrollTop = top;
        }
    }

    render() {
        const { title, error, children, closeModal, workingTitle, workingMessage, onSavePatient, working } = this.props;

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                className="editor-modal"
                contentLabel="Edit Patient"
                style={modalStyles.largeSquareModal}
                closeTimeoutMS={250}>

                <div className="editor-modal-container">
                        <header className="modal-header">
                            <h2>{title}</h2>
                            <button className="close-btn" onClick={closeModal}>
                                <i className="icon-close-x" />
                                <span className="assistive-text">Close Modal</span>
                            </button>
                        </header>

                        <div className="editor-scrollable" ref={this.realizeScrollable}>
                            {children}

                            <footer className="modal-footer">
                                <div className="error-msg" data-active={error ? true : false}>{error || '.'}</div>
                                <button className="prev" onClick={closeModal}>Cancel</button>
                                <button className="next" onClick={onSavePatient}>Save</button>
                            </footer>
                        </div>

                    {working ? <Working title={workingTitle} message={workingMessage} /> : null}
                </div>
            </Modal>
        );
    }
}
