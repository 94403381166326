'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';

import UserStore from '../../../stores/UserStore';

import './FreeTrialBanner.scss';

export default class FreeTrialBanner extends Component {
    static contextTypes = {
        location: PropTypes.object,
        router: PropTypes.object,
    }

    constructor(props) {
        super(props);

        this.state = {
            user: UserStore.getUser(),
        };
    }

    componentDidMount = () => {
        UserStore.addChangeListener(this.onUserStoreChange);
    }

    componentWillUnmount = () => {
        UserStore.removeChangeListener(this.onUserStoreChange);
    }

    onUserStoreChange = () => {
        this.setState({user: UserStore.getUser()});
    }

    render() {
        const { user } = this.state;

        if (!user) {
            return <span />
        }

        const { practice } = user;

        if (!practice) {
            return <span />
        }

        let banner = null;

        const activeUntil = moment(practice.active_until);
        const isExpired = moment(practice.active_until).isBefore(moment());

        if (practice.subscription_plan === 'beta' && isExpired) {
            banner = (
                <div className="pro-free-trial-banner pro-patient-limits-banner"><div className="banner-container">
                    <h3>Beta Testing period has ended</h3>
                    <p>Thank you for participating as a beta user! Upgrade your membership to continue providing personalized meal plans to your {user.practice_type === 'dietetics' ? 'patients' : 'clients'}.</p>

                    <footer>
                        <Link to="/membership"><button>Choose Membership</button></Link>
                    </footer>
                </div></div>
            );
        } else if (practice.subscription_plan === 'free_trial' && isExpired) {
            banner = (
                <div className="pro-free-trial-banner pro-patient-limits-banner"><div className="banner-container">
                    <h3>Free Trial period has ended</h3>
                    <p>Upgrade your membership to continue providing personalized meal plans to your {user.practice_type === 'dietetics' ? 'patients' : 'clients'}.</p>

                    <footer>
                        <Link to="/membership"><button>Choose Membership</button></Link>
                    </footer>
                </div></div>
            );
        } else if (practice.subscription_plan === 'canceled' && isExpired) {
            banner = (
                <div className="pro-free-trial-banner"><div className="banner-container">
                    <h3>Your membership has expired</h3>
                    <p>To continue sending personalized meal plans to your {user.practice_type === 'dietetics' ? 'patients' : 'clients'}, update your membership payment.</p>

                    <footer>
                        <Link to="/membership"><button>Update Payment Info</button></Link>
                    </footer>
                </div></div>
            );
        }

        return (
            <span>{banner}</span>
        );
    }
}
