'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';

import CollectionEditor from '../../Collections/Editor.react';
// import CollectionViewer from '../../Collections/Viewer.react';

import '../../FeedModals.scss';
import './CollectionDetailsModal.scss';

export default class CollectionDetailsModal extends Component {

    static propTypes = {
        modalTitle: PropTypes.string,
    };

    static defaultProps = {
        modalTitle: 'Add Food',
    };

    render() {
        const { closeModal } = this.props;

        return (
            <Modal isOpen={true}
                closeModal={this.closeEditMealModal}
                className="el-modal el-modal2"
                overlayClassName="el-modal-overlay"
                contentLabel="Edit Recipe Details">
                <div className="el-modal-container el-modal2-container collection-editor-modal-container">
                    <CollectionEditor {...this.props} headerText="Collection" />
                </div>
            </Modal>
        );
    }
}
