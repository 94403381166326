'use strict'

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import Helmet from 'react-helmet';

import LoginRequired from '../components/Widgets/LoginRequired.react';

import UserStore from '../../stores/UserStore';

import './MyAccount.scss';

export default class MyAccount extends Component {

    static contextTypes = {
        router: PropTypes.object,
        breakpoint47em: PropTypes.bool,
    };

    constructor(props, context) {
        super(props);

        this.state = {
            user: UserStore.getUser(),
            mode: this.getMode(context.router),
        };
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.setState({
            mode: this.getMode(this.context.router)
        });
    }

    componentDidMount = () => {
        UserStore.addChangeListener(this.onUserStoreChange);
    }

    componentWillUnmount = () => {
        UserStore.removeChangeListener(this.onUserStoreChange);
    }

    getMode(router) {
        if (router.isActive('/my-account/settings')) {
            return 'meta';
        } else if (router.isActive('/my-account/membership')) {
            return 'subscription';
        } else if (router.isActive('/my-account/practice')) {
            return 'practice';
        } else if (router.isActive('/my-account/notifications')) {
            return 'notifications';
        } else if (router.isActive('/my-account/professional')) {
            return 'professional';
        }

        return 'meta';
    }

    onUserStoreChange = () => {
        this.setState({
            user: UserStore.getUser(),
            preferences: UserStore.getRawPreferences(),
        });
    }

    setMode = (mode) => {
        this.setState({mode});
    }

    render = () => {
        const { mode, user } = this.state;
        const { breakpoint47em } = this.context;

        if (!(user && user.links && user.links.practice && user.dietitian)) {
            return <LoginRequired user={user} />;
        }

        const { dietitian, practice } = user;

        return (
            <div>
                <Helmet title="My Account | EatLove PRO" />

                <div className="pro-my-account">
                    <div className="account-tabs">
                        <Link to={mode === 'meta' && !breakpoint47em ? '/my-account' : "/my-account/settings"}>
                            <button className={mode === 'meta' ? "account-tab active-tab" : "account-tab"} data-tab="name" >
                                <div className="inner-container">
                                    <i className="icon-settings" />
                                    <div>
                                        <p>Account Settings</p>
                                        <span>Email &amp; Password</span>
                                    </div>
                                </div>
                            </button>
                        </Link>
                        <section className="account-expansion" data-status={mode === 'meta' ? true : false}>
                            <div className="inner-container">
                                {mode === "meta" && !breakpoint47em ? this.props.children : null }
                            </div>
                        </section>

                        <Link to={mode === 'notifications' && !breakpoint47em ? '/my-account' : "/my-account/notifications"}>
                            <button className={mode === 'notifications' ? "account-tab active-tab" : "account-tab"} data-tab="notifications">
                                <div className="inner-container">
                                    <i className="icon-email" />
                                    <div>
                                        <p>Notifications</p>
                                        <span>Manage Emails</span>
                                    </div>
                                </div>
                            </button>
                        </Link>
                        <section className="account-expansion" data-status={mode === 'notifications' ? true : false}>
                            <div className="inner-container">
                                { mode === "notifications" && !breakpoint47em ? this.props.children : null }
                            </div>
                        </section>

                        {dietitian.practice_role === 'admin' ?
                            <Link to={mode === 'practice' && !breakpoint47em ? '/my-account' : "/my-account/practice"}>
                                <button className={mode === 'practice' ? "account-tab active-tab" : "account-tab"} data-tab="practice">
                                    <div className="inner-container">
                                        <i className="icon-leaf" />
                                        <div>
                                            <p>{practice.practice_type === 'dietetics' ? 'Practice' : 'Business'}</p>
                                            <span>Business Settings</span>
                                        </div>
                                    </div>
                                </button>
                            </Link>
                        : null}
                        {dietitian.practice_role === 'admin' ?
                            <section className="account-expansion" data-status={mode === 'practice' ? true : false}>
                                <div className="inner-container">
                                    { mode === "practice" && !breakpoint47em ? this.props.children : null }
                                </div>
                            </section>
                        : null}

                        <Link to={mode === 'subscription' && !breakpoint47em ? '/my-account' : "/my-account/membership"}>
                            <button className={mode === 'subscription' ? "account-tab active-tab" : "account-tab"} data-tab="subscription">
                                <div className="inner-container">
                                    <i className="icon-receipt" />
                                    <div>
                                        <p>Membership</p>
                                        <span>Manage Payments</span>
                                    </div>
                                </div>
                            </button>
                        </Link>
                        <section className="account-expansion" data-status={mode === 'subscription' ? true : false}>
                            <div className="inner-container">
                                { mode === "subscription" && !breakpoint47em ? this.props.children : null }
                            </div>
                        </section>

                        <Link to={mode === 'professional' && !breakpoint47em ? '/my-account' : "/my-account/professional"}>
                            <button className={mode === 'professional' ? "account-tab active-tab" : "account-tab"} data-tab="professional">
                                <div className="inner-container">
                                    <i className="icon-dashboard1"  />
                                    <div>
                                        <p>Professional Info</p>
                                        <span>License & Specialties</span>
                                    </div>
                                </div>
                            </button>
                        </Link>
                        <section className="account-expansion" data-status={mode === 'professional' ? true : false}>
                            <div className="inner-container">
                                { mode === "professional" && !breakpoint47em ? this.props.children : null }
                            </div>
                        </section>
                    </div>

                    { breakpoint47em ?
                        <div className="account-main">
                            <div className="inner-container">
                                {this.props.children}
                            </div>
                        </div>
                    : null }
                </div>
            </div>
        );
    }
}
