'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import MealPreferences from '../MealPreferences.react';
import EditorModal from '../../../Modals/EditorModal.react';

import AuthStore from '../../../../../stores/AuthStore';
import { getConfig } from '../../../../../utils/Env';
import Analytics from '../../../../../utils/Analytics';
import { isSameValue } from '../../../../../utils/Util';
import { titleCase } from '../../../../../utils/TextUtil';

export default class MealPreferencesEditorModal extends Component {
    static propTypes = {
    };

    static contextTypes = {
        confirm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            working: false,
        };
    }

    savePatient = () => {
        let { patient, closeModal, onChangePatient } = this.props;

        const error = this.refs.editor.validate();

        // Validate the form browser-side first.
        if (error !== true) {
            this.setState({error});

            return;
        }

        const currentPreferences = { ...patient.preferences };
        // Mutate the patient record
        patient = this.refs.editor.mutate(patient);

        // Omit the stuff from the patient record that's read-only. E.g.: we can't change the uuid
        const { uuid, links, created, user, invite_sent,
                invite_accepted, recommendations, active,
                activated_date, deactivated_date, ...rest } = patient;

        this.setState({working: true});

        // Save the record
        AuthStore.fetch(getConfig('users_api') + patient.links.self.href, {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=patient/1'},
            body: JSON.stringify(rest),
        }).then(
            response => {
                const preferencesUpdated = Object.keys(currentPreferences)
                    .filter(key => !isSameValue(currentPreferences[key], patient.preferences[key]));

                Analytics.updatePatientMealPreferences({
                    'Patient UUID': patient.uuid,
                    'Preferences': preferencesUpdated.map(p => titleCase(p))
                });

                // Notify the parent that there's been a change and
                onChangePatient(patient);

                closeModal();
            },
            error => {
                this.setState({working: false, error: error.message});
            }
        );
    }

    closeModal = (page) => {
        const { closeModal } = this.props;

        if (this.refs.editor.isDirty()) {
            this.context.confirm(
                <div className="confirm-discard-changes">
                    <h6>Discard changes?</h6>
                    <p>You&apos;ve made changes to this patient, are you sure you want to discard those changes?</p>
                </div>,
                closeModal,
                () => false,
                {
                    acceptText: 'Discard Changes'
                }
            );

            return;
        }

        closeModal();
    }

    render() {
        const { patient } = this.props;
        const { error, working } = this.state;

        return (
            <EditorModal title="Edit Meal Preferences" error={error}
                closeModal={this.closeModal} working={working} patient={patient}
                onSavePatient={this.savePatient}>
                <MealPreferences patient={patient} ref="editor" />
            </EditorModal>
        );
    }
}
