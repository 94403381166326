'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import moment from 'moment';

import InviteOrComplete from './InviteOrComplete.react';
import ImgResized from '../../../components/Widgets/ImgResized.react';

import { computeBmi, cmToFeetInches } from '../../utils/Patients';
import { roundForHumans } from '../../../utils/Math';

import './InfoCard.scss';

export default class InfoCard extends Component {

    renderPatientGeometry = (patient) => {
        const { height_cm, weight_kg } = patient;
        const { units_mode } = patient;

        let height = (height_cm + ' cm');
        let weight = (weight_kg + ' kg');

        if (units_mode === 'english') {
            let { feet, inches } = cmToFeetInches(patient.height_cm);

            height = `${feet}' ${inches}"`;
            weight = Math.round(weight_kg * 2.20462) + ' lbs';
        }

        const bmi = computeBmi(weight_kg, patient.height_cm);

        return [
            <span key="height"><em>{height}</em></span>,
            <span key="weight">Current Weight <em>{weight}</em></span>
        ];
    }

    render = () => {
        const { patient, working, sendPatientInvite, showEditMeta, reactivatePatient, closePatientCardModal } = this.props;
        const age = Math.floor(moment().diff(patient.birthdate, 'years', true));

        return (
            <section className="patient-info-card">
                <div className ="personal-info">
                    <p className="name">{patient.first_name} {patient.last_name}</p>
                    {patient.patient_number ? <p className="patient-number">{patient.patient_number}</p> : null}

                    <section className="contact">
                        {patient.image ?
                            <ImgResized className="headshot" src={patient.image} width={140} height={140} />
                        : null}

                        {!patient.image ?
                            <i className={patient.gender === 'male' ? 'headshot icon-male2' : 'headshot icon-female2'} />
                        : null}


                        <div className="row2">
                            <p className="email">{patient.email}</p>
                            <p className="mobile-number">{patient.mobile_number}</p>
                            {!patient.invite_sent ?
                                <p className="send-msg"><button onClick={sendPatientInvite}>SEND INVITE</button></p>
                            : null}
                            {patient.invite_sent && !patient.invite_accepted ?
                                <p className="send-msg"><button onClick={sendPatientInvite}>RESEND INVITE</button></p>
                            : null}
                            {patient.invite_accepted ?
                                <p className="send-msg" onClick={closePatientCardModal ? closePatientCardModal : null}>
                                    <Link to={`/clients/${patient.uuid}/messages`}>SEND MESSAGE</Link>
                                </p>
                            : null}
                        </div>
                    </section>

                    <section className="bio">
                        {patient.birthdate && patient.gender? <p className="age-gender">{age} year old {patient.gender}</p> : null}
                        {patient.height_cm && patient.weight_kg ? <p className="height-weight">
                            {this.renderPatientGeometry(patient)}
                        </p> : null}
                        {(patient.conditions || []).length > 0 ? <p className="conditions">
                            {patient.practice_type === 'dietetics' ? 'Prescription' : 'Lifestyle Goal'}:&nbsp;
                            <em>{(patient.conditions || []).map(cd => cd.name).join(', ')}</em>
                        </p> : null}
                    </section>
                </div>

                <footer>
                    <InviteOrComplete className="send-invite-btn" patient={patient} showExpiration={'info_card'}
                        showEditMeta={showEditMeta} sendPatientInvite={sendPatientInvite} reactivatePatient={reactivatePatient} />

                    {patient.active ?
                        <button className="edit-meta-btn" onClick={showEditMeta}>Edit</button>
                    : null}
                </footer>
            </section>
        );
    }
}
