'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';

import { getConfig } from '../../../utils/Env';
import AuthActions from '../../../actions/AuthActions';

import LoginForm from '../../../components/Widgets/LoginForm.react';

import './LoginRequired.scss';

export default class LoginRequired extends Component {
    static propTypes = {
        user: PropTypes.object,
    };

    logout = () => {
        AuthActions.deauthorize();
    }

    render() {
        const { user } = this.props;

        const wwwUrl = 'https://' + getConfig('www_host');

        return (
            <div>
                <div className="pro-login-required">
                    {user && !user.dietitian ?
                        <span>
                            <h2>You're signed in as <em>{user.email}</em></h2>

                            {user.my_dietitian ?
                                <p>Please <a href="https://www.eatlove.is/">click here</a> to return to EatLove</p>
                            : null}

                            <footer>
                                <button onClick={this.logout}>sign out</button>
                                {user.my_dietitian
                                    ? <a href="https://www.eatlove.is/"><button>Return to EatLove</button></a>
                                    : <Link to="/new-account"><button>Sign Up Free</button></Link>
                                }
                            </footer>
                        </span>
                    : null}

                    {!user ?
                        <span>
                            <LoginForm formSubtitleText="Please sign-in to access your EatLove PRO account."
                                hideIDPs hideRetrieveInvite returnToLanding={wwwUrl + '/pro'} />
                        </span>
                    : null}
                </div>
            </div>
        );
    }
}
