'use strict';
import React from 'react';
import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';

import PrescriptionMismatch from './PrescriptionMismatch.react';
import ImgResized from '../../../../components/Widgets/ImgResized.react';

import { getRecipeMismatchesForProfile, getPlanMismatchesForProfile } from '../../../utils/Patients';
import { getDietsFromTags, getAvoidancesFromProps } from '../../../../utils/Diets';

import './Activity.scss';

export default class Activity extends Component {
    static propTypes = {
    };

    static contextTypes = {
        showCustomizer: PropTypes.func,
        showRecipeDetails: PropTypes.func,
        showPatientCustomizer: PropTypes.func,
        showPatientRecipeDetails: PropTypes.func,
        showCollectionDetails: PropTypes.func,
    };

    onClickRecipe = (recipe) => {
        const { showPatientRecipeDetails, showRecipeDetails } = this.context;
        const { activity } = this.props;
        const { patient } = activity;

        if (!recipe) {
            return;
        }

        if (showPatientRecipeDetails) {
            showPatientRecipeDetails(patient, recipe);
        } else if (showRecipeDetails) {
            showRecipeDetails(recipe);
        }
    }

    onClickPlan = (plan) => {
        const { showPatientCustomizer, showCustomizer } = this.context;
        const { activity } = this.props;
        const { patient } = activity;

        if (!plan) {
            return;
        }

        if (showPatientCustomizer) {
            showPatientCustomizer(patient, plan);
        } else if (showCustomizer) {
            showCustomizer(plan);
        }
    }

    onClickCollection = (collection) => {
        const { showCollectionDetails } = this.context;

        if (!collection) {
            return;
        }

        if (showCollectionDetails) {
            showCollectionDetails(collection);
        }
    }

    renderUpdatePrefs = () => {
        const { activity, assets, keyIndex } = this.props;
        const { created, patient, event_type, event_data = {} } = activity;
        const { diets = {'-': [], '+': []}, avoidances = {'-': [], '+': []}, exclude_foods = {'-': [], '+': []},
                breakfasts, lunches, dinners, snacks } = event_data;

        if (typeof diets['-'] === 'string') {
            diets['-'] = [diets['-']];
        }

        if (typeof diets['+'] === 'string') {
            diets['+'] = [diets['+']];
        }

        diets['-'] = diets['-'] || [];
        diets['+'] = diets['+'] || [];

        const removedDiets = getDietsFromTags((diets['-'] || []).filter(d => !diets['+'].includes(d)));
        const addedDiets = getDietsFromTags((diets['+'] || []).filter(d => !diets['-'].includes(d)));

        if (typeof avoidances['-'] === 'string') {
            avoidances['-'] = [avoidances['-']];
        }

        if (typeof avoidances['+'] === 'string') {
            avoidances['+'] = [avoidances['+']];
        }

        avoidances['-'] = avoidances['-'] || [];
        avoidances['+'] = avoidances['+'] || [];

        const removedAvoids = (avoidances['-'] || []).filter(d => !avoidances['+'].includes(d)).map(d => keyIndex[d]).filter(v => v);
        const addedAvoids = (avoidances['+'] || []).filter(d => !avoidances['-'].includes(d)).map(d => keyIndex[d]).filter(v => v);

        exclude_foods['-'] = exclude_foods['-'] || [];
        exclude_foods['+'] = exclude_foods['+'] || [];

        if (typeof exclude_foods['-'] === 'string') {
            exclude_foods['-'] = exclude_foods['-'].split(',');
        }

        if (typeof exclude_foods['+'] === 'string') {
            exclude_foods['+'] = exclude_foods['+'].split(',');
        }

        const removedExcludeFoods = (exclude_foods['-'] || []).filter(d => !exclude_foods['+'].includes(d)).map(d => assets[d]).filter(v => v);
        const addedExcludeFoods = (exclude_foods['+'] || []).filter(d => !exclude_foods['-'].includes(d)).map(d => assets[d]).filter(v => v);

        const content = [];

        if (addedDiets.length > 0) {
            content.push(<span key="added-diets"><em>Added:</em> {addedDiets.map(d => d.name).join(', ')}</span>);
        }

        if (removedDiets.length > 0) {
            content.push(<span key="remove-diets"><em>Removed Diet:</em> {removedDiets.map(d => d.name).join(', ')}</span>);
        }

        if (addedAvoids.length > 0) {
            content.push(<span key="avoiding"><em>Avoiding:</em> {addedAvoids.map(d => d.title).join(', ')}</span>);
        }

        if (removedAvoids.length > 0) {
            content.push(<span key="reintro"><em>Reintroducing:</em> {removedAvoids.map(d => d.title).join(', ')}</span>);
        }

        if (addedExcludeFoods.length > 0) {
            content.push(<span key="avoiding"><em>Avoiding:</em> {addedExcludeFoods.map(d => d.name).join(', ')}</span>);
        }

        if (removedExcludeFoods.length > 0) {
            content.push(<span key="reintro"><em>Reintroducing:</em> {removedExcludeFoods.map(d => d.name).join(', ')}</span>);
        }

        if (breakfasts) {
            content.push(<span key="breakfasts"><em>Include Breakfasts:</em> {breakfasts['+'] || 'none'}</span>);
        }

        if (lunches) {
            content.push(<span key="lunches"><em>Include Lunches:</em> {lunches['+'] || 'none'}</span>);
        }

        if (dinners) {
            content.push(<span key="dinners"><em>Include Dinners:</em> {dinners['+'] || 'none'}</span>);
        }

        if (snacks) {
            content.push(<span key="snacks"><em>Include Snacks:</em> {snacks['+'] || 'none'}</span>);
        }

        return (
            <li className="dashboard-activity update-prefs">
                <div className="thumb">
                    <i className="icon-settings2" />
                </div>

                <div className="container">
                    <h6><Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> updated their preferences:</h6>
                    <p className="content">{content}</p>
                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>
                </div>
            </li>
        );
    }

    renderAcceptInvite = () => {
        const { activity } = this.props;
        const { created, patient, event_type, event_data = {} } = activity;

        return (
            <li className="dashboard-activity">
                <div className="thumb">
                    <i className="icon-email" />
                </div>

                <div className="container">
                    <h6>
                        <Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> has accepted your invitation to EatLove.
                    </h6>

                    {event_data.family_review_needed ?
                        <p>There was a discrepency with {patient.first_name}&apos;s family - they had a different number of family members under their account before accepting the invite. Please review with them to ensure that their family members are setup correctly, to ensure EatLove will generate meal plans with enough food for the whole family.</p>
                    : null}

                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>

                </div>
            </li>
        );
    }

    getPlanFromActivity = (activity) => {
        const { event_data, patient } = activity;
        const { assets } = this.props;

        const planUuid = event_data.plan_uuid || event_data['Meal Plan ID'] ||  event_data['Meal Plan UUID'];
        const plan = assets[planUuid];
        const date = moment(event_data.date_start || event_data['Plan Start Date']);

        if (!plan) {
            return {plan: null, image: null, mismatches: [], date};
        }

        const image = plan.image;

        const mismatches = getPlanMismatchesForProfile(plan, patient.prescriptions);

        return {plan, image, mismatches, date};
    }

    getCollectionFromActivity = (activity) => {
        const { event_data, patient } = activity;
        const { assets } = this.props;

        const uuid = event_data.uuid;
        const collection = assets[uuid];

        return { collection }
    }

    renderViewMealPlanNutrition = () => {
        const { activity } = this.props;
        const { created, patient, event_data = {} } = activity;
        const { plan, image, mismatches, date } = this.getPlanFromActivity(activity);

        if (!plan) {
            return <span />;
        }

        return (
            <li className="dashboard-activity">
                <div className="thumb">
                    <ImgResized className="icon" onClick={() => this.onClickPlan(plan)} src={image} width={50} height={50} />
                </div>

                <div className="container">
                    <h6>
                        <span>
                            {event_data['Recommended By'] ?
                                <span><Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> viewed nutrition info for your recommended meal plan:&nbsp;</span>
                            : null}
                            {!event_data['Recommended By'] ?
                                <span><Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> viewed info for a meal plan:&nbsp;</span>
                            : null}
                            <em onClick={() => this.onClickPlan(plan)}>{plan.title}</em>
                        </span>
                    </h6>
                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>
                </div>
            </li>
        );
    }

    renderCustomizePlan = () => {
        const { activity } = this.props;
        const { created, patient, event_data = {} } = activity;
        const { plan, image, mismatches, date } = this.getPlanFromActivity(activity);

        const planTitle = event_data['Meal Plan Name'] || (plan && plan.title) || 'Untitled Meal Plan';

        return (
            <li className="dashboard-activity">
                <div className="thumb">
                    <ImgResized className="icon" onClick={() => this.onClickPlan(plan)} src={image} width={50} height={50} />
                </div>

                <div className="container">
                    <h6>
                        <span>
                            {event_data['Recommended By'] ?
                                <span><Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> viewed your recommended meal plan:&nbsp;</span>
                            : null}
                            {!event_data['Recommended By'] ?
                                <span><Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> viewed a meal plan:&nbsp;</span>
                            : null}
                            <em onClick={() => this.onClickPlan(plan)}>{planTitle}</em>
                        </span>
                    </h6>
                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>
                </div>
            </li>
        );
    }

    renderOpenCollection = () => {
        const { activity } = this.props;
        const { created, patient, event_data = {} } = activity;
        const { collection, date } = this.getCollectionFromActivity(activity);

        if (!collection) {
            return <span />
        }

        return (
            <li className="dashboard-activity">
                <div className="thumb">
                    <ImgResized className="icon" onClick={() => this.onClickCollection(collection)} src={collection.image} width={50} height={50} />
                </div>

                <div className="container">
                    <h6>
                        <span>
                            {event_data['Recommended By'] ?
                                <span><Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> viewed your recipe collection:&nbsp;</span>
                            : null}
                            {!event_data['Recommended By'] ?
                                <span><Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> viewed a recipe collection:&nbsp;</span>
                            : null}
                            <em onClick={() => this.onClickCollection(collection)}>{collection.title}</em>
                        </span>
                    </h6>
                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>
                </div>
            </li>
        );
    }

    renderAddAMealPlan = () => {
        const { activity } = this.props;
        const { created, patient, event_data = {} } = activity;
        const { plan, image, mismatches, date } = this.getPlanFromActivity(activity);

        if (!plan) {
            return <span />;
        }

        return (
            <li className="dashboard-activity">
                <div className="thumb">
                    <ImgResized className="icon" onClick={() => this.onClickPlan(plan)} src={image} width={50} height={50} />
                </div>

                <div className="container">
                    <h6>
                        <span>
                            {event_data['Recommended By'] ?
                                <span><Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> scheduled your recommended meal plan starting {date.format('ddd, MMM Do')}:&nbsp;</span>
                            : null}
                            {!event_data['Recommended By'] ?
                                <span><Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> scheduled a meal plan starting {date.format('ddd, MMM Do')}:&nbsp;</span>
                            : null}
                            <em onClick={() => this.onClickPlan(plan)}>{plan.title}</em>
                        </span>
                    </h6>
                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>
                </div>

                {mismatches.length > 0 ? <PrescriptionMismatch mismatches={mismatches} /> : null}
            </li>
        );
    }

    renderViewRecipe = () => {
        const { activity, assets } = this.props;
        const { created, patient, event_data = {} } = activity;

        const recipe = (event_data['Recipe ID'] && assets[event_data['Recipe ID']]) ||
                       (event_data['Recipe UUID'] && assets[event_data['Recipe UUID']]) ||
                       (event_data.recipe_uuid && assets[event_data.recipe_uuid]);

        if (!recipe) {
            return <span />;
        }

        return (
            <li className="dashboard-activity">
                <div className="thumb">
                    <ImgResized className="icon" onClick={() => this.onClickRecipe(recipe)} src={recipe.image} width={50} height={50} />
                </div>

                <div className="container">
                    <h6>
                        <Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> viewed recipe:&nbsp;
                        <em onClick={() => this.onClickRecipe(recipe)}>{recipe.title}</em>
                    </h6>
                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>
                </div>
            </li>
        );
    }

    renderScheduleRecipe = () => {
        const { activity, assets } = this.props;
        const { created, patient, event_data = {} } = activity;

        const recipe = (event_data['Recipe ID'] && assets[event_data['Recipe ID']]) ||
                       (event_data['Recipe UUID'] && assets[event_data['Recipe UUID']]) ||
                       (event_data.recipe_uuid && assets[event_data.recipe_uuid]);

        if (!recipe) {
            return <span />;
        }

        const mealType = event_data.meal_type || event_data['Meal Name'] || 'Dinner';
        const date = moment(event_data.date || event_data['Meal Date']);

        const mismatches = getRecipeMismatchesForProfile(recipe, event_data.meal_type, patient.prescriptions);

        return (
            <li className="dashboard-activity">
                <div className="thumb">
                    <ImgResized className="icon" onClick={() => this.onClickRecipe(recipe)} src={recipe.image} width={50} height={50} />
                </div>

                <div className="container">
                    <h6 onClick={() => this.onClickRecipe(recipe)}>
                        <Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> scheduled a&nbsp;
                        {mealType.toLowerCase()} recipe for {date.format('ddd, MMM Do')}:&nbsp;
                        <em onClick={() => this.onClickRecipe(recipe)}>{recipe.title}</em>
                    </h6>
                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>
                </div>

                {mismatches.length > 0 ? <PrescriptionMismatch mismatches={mismatches} /> : null}
            </li>
        );
    }

    renderCheckedOffGroceries = () => {
        const { activity } = this.props;
        const { created, patient, event_data = {} } = activity;
        const { groceries = [] } = event_data;

        return (
            <li className="dashboard-activity">
                <div className="thumb">
                    <i className="icon-cart" />
                </div>

                <div className="container">
                    <h6><Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> checked {groceries.length} {groceries.length == 1 ? 'item' : 'items'} off their grocery list</h6>
                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>
                </div>
            </li>
        );
    }

    renderLogCurrentWeight = () => {
        const { activity } = this.props;
        const { created, patient } = activity;

        return (
            <li className="dashboard-activity">
                <div className="thumb">
                    <i className="icon-cart" />
                </div>

                <div className="container">
                    <h6><Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> logged their current weight.</h6>
                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>
                </div>
            </li>
        );
    }

    renderAppLaunched = () => {
        const { activity } = this.props;
        const { created, patient } = activity;

        return (
            <li className="dashboard-activity">
                <div className="thumb">
                    <i className="icon icon-logo2"><span className="path1" /><span className="path2" /></i>
                </div>

                <div className="container">
                    <h6><Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> returned to EatLove</h6>
                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>
                </div>
            </li>
        );
    }

    renderViewPrepList = () => {
        const { activity } = this.props;
        const { created, patient } = activity;

        return (
            <li className="dashboard-activity">
                <div className="thumb">
                    <i className="icon-clock2" />
                </div>

                <div className="container">
                    <h6><Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> viewed their prep tasks list.</h6>
                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>
                </div>
            </li>
        );
    }

    renderViewGroceryList = () => {
        const { activity } = this.props;
        const { created, patient } = activity;

        return (
            <li className="dashboard-activity">
                <div className="thumb">
                    <i className="icon-cart" />
                </div>

                <div className="container">
                    <h6><Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> viewed their grocery list.</h6>
                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>
                </div>
            </li>
        );
    }


    renderSubmitGroceryListForReview = () => {
        const { activity } = this.props;
        const { created, patient } = activity;

        return (
            <li className="dashboard-activity">
                <div className="thumb">
                    <i className="icon-cart" />
                </div>

                <div className="container">
                    <h6><Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> submitted their grocery list for review</h6>
                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>
                </div>
            </li>
        );
    }

    renderLogin = () => {
        const { activity } = this.props;
        const { created, patient } = activity;

        return (
            <li className="dashboard-activity">
                <div className="thumb">
                    <i className="icon icon-logo2"><span className="path1" /><span className="path2" /></i>
                </div>

                <div className="container">
                    <h6><Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> Signed In</h6>
                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>
                </div>

            </li>
        );
    }

    renderSearch = () => {
        const { activity } = this.props;
        const { created, patient } = activity;
        const { event_data } = activity;

        const content = [];

        if (event_data['Searched Type']) {
            let searched = event_data['Searched Type'] || [];

            if (searched.includes('recipe') && searched.includes('plan')) {
                content.push(<span key="types">for recipes and meal plans&nbsp;</span>);
            } else if (searched.includes('recipe')) {
                content.push(<span key="types">for recipes&nbsp;</span>);
            } else if (searched.includes('plan')) {
                content.push(<span key="types">for meal plans&nbsp;</span>);
            }
        }

        if (event_data['Terms']) {
            content.push(<span key="terms">matching keyword <em>{event_data['Terms']}</em>&nbsp;</span>);
        }

        return (
            <li className="dashboard-activity">
                <div className="thumb">
                    <i className="icon-search3" />
                </div>

                <div className="container">
                    <h6><Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> searched</h6>
                    <p>{content}</p>
                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>
                </div>

            </li>
        );
    }

    getReplacedFromActivity = (activity) => {
        const { assets } = this.props;
        const { event_data } = activity;

        const offset = (typeof event_data['Offset'] !== 'undefined')
                     ? ((parseInt(event_data['Offset']) || 0) + 1)
                     : 1;
        const mealType = event_data['Meal'] || event_data['Meal Type'] || 'Dinner';

        const replacedMain = assets[event_data['Replaced Recipe ID']] || assets[event_data['Replaced Recipe UUID']];
        const replacedSide = assets[event_data['Replaced Side ID']] || assets[event_data['Replaced Side UUID']];

        const newMain = assets[event_data['New Recipe ID']] || assets[event_data['New Recipe UUID']];
        const newSide = assets[event_data['New Side ID']] || assets[event_data['New Side UUID']];

        const plan = assets[event_data['Meal Plan ID']] || assets[event_data['Meal Plan UUID']];

        return {
            offset, mealType, replacedMain, replacedSide, newMain, newSide, plan,
            isRecommended: (typeof event_data['Recommended By'] === 'string'),
        };
    }

    renderReplaceRecipe = () => {
        const { activity } = this.props;
        const { created, patient, event_data = {} } = activity;

        const { offset, mealType, replacedMain, replacedSide, newMain, newSide, plan, isRecommended } = this.getReplacedFromActivity(activity);

        if (!replacedMain || !newMain) {
            return <span />;
        }

        const planTitle = event_data['Meal Plan Name'] || (plan && plan.title) || 'Untitled Meal Plan';

        return (
            <li className="dashboard-activity">
                <div className="thumb">
                    <ImgResized className="icon" onClick={() => this.onClickRecipe(newMain)} src={newMain.image} width={50} height={50} />
                </div>

                <div className="container">
                    <h6>
                        <Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> replaced&nbsp;
                        <em onClick={() => this.onClickRecipe(replacedMain)}>{replacedMain.title}</em>&nbsp;
                        {replacedSide ? <span>+ <em onClick={() => this.onClickRecipe(replacedSide)}>{replacedSide.title}</em></span> : null} with&nbsp;
                        <em onClick={() => this.onClickRecipe(newMain)}>{newMain.title}</em>&nbsp;
                        {newSide ? <span>+ <em onClick={() => this.onClickRecipe(newSide)}>{newSide.title}</em></span> : null} in&nbsp;
                        {isRecommended ? 'your recommended meal plan' : 'meal plan'}:&nbsp;
                        <em onClick={() => this.onClickPlan(plan)}>{planTitle}</em>
                    </h6>
                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>
                </div>

            </li>
        );
    }

    getAddRecipeFromActivity = (activity) => {
        const { assets } = this.props;
        const { event_data } = activity;

        const offset = (typeof event_data['Offset'] !== 'undefined')
                     ? ((parseInt(event_data['Offset']) || 0) + 1)
                     : 1;
        const mealType = event_data['Meal'] || event_data['Meal Type'] || 'Dinner';

        const mainDish = assets[event_data['Recipe ID']] || assets[event_data['Recipe UUID']];
        const sideDish = assets[event_data['Side ID']] || assets[event_data['Side UUID']];

        const plan = assets[event_data['Meal Plan ID']] || assets[event_data['Meal Plan UUID']];

        return {
            plan, offset, mealType, mainDish, sideDish,
            isRecommended: (typeof event_data['Recommended By'] === 'string')
        };
    }

    renderAddRecipe = () => {
        const { activity } = this.props;
        const { created, patient, event_data = {} } = activity;

        const {plan, offset, mealType, mainDish, sideDish, isRecommended} = this.getAddRecipeFromActivity(activity);

        if (!mainDish) {
            return <span />;
        }

        const planTitle = event_data['Meal Plan Name'] || (plan && plan.title) || 'Untitled Meal Plan';

        return (
            <li className="dashboard-activity">
                <div className="thumb">
                    <ImgResized className="icon" onClick={() => this.onClickRecipe(mainDish)} src={mainDish.image} width={50} height={50} />
                </div>

                <div className="container">
                    <h6>
                        <Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> added&nbsp;
                        <em onClick={() => this.onClickRecipe(mainDish)}>{mainDish.title}</em>&nbsp;
                        {sideDish ? <span>+ <em onClick={() => this.onClickRecipe(sideDish)}>{sideDish.title}</em></span> : null} to&nbsp;
                        {isRecommended ? 'your recommended meal plan' : 'meal plan'}:&nbsp;
                        <em onClick={() => this.onClickPlan(plan)}>{planTitle}</em>
                    </h6>

                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>
                </div>

            </li>
        );

    }

    renderRemoveRecipe = () => {
        const { activity } = this.props;
        const { created, patient, event_data = {} } = activity;

        const {plan, offset, mealType, mainDish, sideDish, isRecommended } = this.getAddRecipeFromActivity(activity);


        if (!mainDish) {
            return <span />;
        }

        const planTitle = event_data['Meal Plan Name'] || (plan && plan.title) || 'Untitled Meal Plan';

        return (
            <li className="dashboard-activity">
                <div className="thumb">
                    <ImgResized className="icon" onClick={() => this.onClickRecipe(mainDish)} src={mainDish.image} width={50} height={50} />
                </div>

                <div className="container">
                    <h6>
                        <Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> removed&nbsp;
                        <em onClick={() => this.onClickRecipe(mainDish)}>{mainDish.title}</em>&nbsp;
                        {sideDish ? <span>+ <em onClick={() => this.onClickRecipe(sideDish)}>{sideDish.title}</em></span> : null} from&nbsp;
                        {isRecommended ? 'your recommended meal plan' : 'meal plan'}:&nbsp;
                        <em onClick={() => this.onClickPlan(plan)}>{planTitle}</em>
                    </h6>

                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>
                </div>

            </li>
        );
    }

    renderSaveFavorite = () => {
        const { activity, assets } = this.props;
        const { created, event_data, patient } = activity;
        const { plan, image } = this.getPlanFromActivity(activity);
        const recipe = assets[event_data['Recipe ID']] || assets[event_data['Recipe UUID']];

        if (!plan && !recipe) {
            return <span />;
        }

        return (
            <li className="dashboard-activity">
                <div className="thumb">
                    {recipe ?
                        <ImgResized className="icon" onClick={() => this.onClickRecipe(recipe)} src={recipe.image} width={50} height={50} />
                    : null}
                    {plan ?
                        <ImgResized className="icon" onClick={() => this.onClickPlan(plan)} src={image} width={50} height={50} />
                    : null}
                </div>

                <div className="container">
                    <h6>
                        <Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> favorited&nbsp;
                        {recipe ? <span>a recipe: <em onClick={() => this.onClickRecipe(recipe)}>{recipe.title}</em></span> : null}
                        {plan ? <span>a meal plan: <em onClick={() => this.onClickPlan(plan)}>{plan.title}</em></span> : null}
                    </h6>

                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>
                </div>

            </li>
        );
    }

    renderDeactivated = () => {
        const { activity } = this.props;
        const { created, patient } = activity;

        return (
            <li className="dashboard-activity">
                <div className="thumb">
                    <i className="feather feather-power" />
                </div>

                <div className="container">
                    <h6>
                        <Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> was deactivated.
                    </h6>

                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>
                </div>
            </li>
        );
    }

    renderReactivated = () => {
        const { activity } = this.props;
        const { created, patient } = activity;

        return (
            <li className="dashboard-activity">
                <div className="thumb">
                    <i className="feather feather-power" />
                </div>

                <div className="container">
                    <h6>
                        <Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> was re-activated.
                    </h6>

                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>
                </div>
            </li>
        );
    }

    renderViewBlog = () => {
        const { activity } = this.props;
        const { created, patient } = activity;

        return (
            <li className="dashboard-activity">
                <div className="thumb">
                    <i className="icon icon-logo2"><span className="path1" /><span className="path2" /></i>
                </div>

                <div className="container">
                    <h6>
                        <Link to={`/clients/${patient.uuid}`}>{patient.first_name} {patient.last_name}</Link> viewed the blog <Link to={activity.event_data["Blog Url"]} target="_blank" rel="noopener noreferrer">{`${activity.event_data["Blog Title"]}`}</Link>
                    </h6>

                    <p className="from-now">
                        <i className="icon-clock1" />
                        <em>{moment(created).fromNow()}</em>
                    </p>
                </div>
            </li>
        );
    }

    render() {
        const { activity } = this.props;
        const { event_type } = activity;

        switch(event_type) {

            case 'App Launched':
                return this.renderAppLaunched();

            case 'Login':
                return this.renderLogin();

            case 'View Meal Plan Customizer':
                return this.renderCustomizePlan();

            case 'Open Collection':
                return this.renderOpenCollection();

            case 'Replace a Recipe':
                return this.renderReplaceRecipe();

            case 'Add a Recipe':
                return this.renderAddRecipe();

            case 'Remove Recipe From Meal Plan Customizer':
                return this.renderRemoveRecipe();

            case 'View Meal Plan Nutrition':
                return this.renderViewMealPlanNutrition();

            case 'Schedule Meal Plan':
                return this.renderAddAMealPlan();

            case 'Schedule Recipe':
                return this.renderScheduleRecipe();

            case 'View Recipe':
                return this.renderViewRecipe();

            case 'View Grocery List':
                return this.renderViewGroceryList();

            case 'Submit Grocery List For Review':
                return this.renderSubmitGroceryListForReview();                

            case 'View Prep List':
                return this.renderViewPrepList();

            case 'Search':
                return this.renderSearch();

            case 'Save Favorite':
                return this.renderSaveFavorite();

            case 'Update Preferences':
                return this.renderUpdatePrefs();

            case 'Accepted Invite':
                return this.renderAcceptInvite();

            case 'Check-off Groceries':
                return this.renderCheckedOffGroceries();

            case 'Log Current Weight':
                return this.renderLogCurrentWeight();

            case 'Deactivated':
                return this.renderDeactivated();

            case 'Reactivated':
                return this.renderReactivated();

            case 'View Blog':
                return this.renderViewBlog();
        }

        return <span />
    }
}
