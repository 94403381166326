'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { getConfig } from '../../../utils/Env';
import AuthStore from '../../../stores/AuthStore';
import UserStore from '../../../stores/UserStore';

import './PatientDeactivator.scss';

export default class PatientDeactivator extends Component {
    static propTypes = {
        patient: PropTypes.object,
        onChangePatient: PropTypes.func,
    };

    static contextTypes = {
        confirm: PropTypes.func,

        showUpgradeForm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            working: false,
        };
    }

    deactivatePatient = () => {
        const { confirm } = this.context;
        const { patient, onChangePatient } = this.props;

        AuthStore.fetch(getConfig('users_api') + patient.links.deactivate.href, {method: 'POST'})
                 .then(
            success => {
                patient.active = 0;
                patient.deactivated_date = moment().format('YYYY-MM-DD hh:mm:ss');
                onChangePatient(patient);

                this.setState({working: false});
            },
            error => {
                confirm(
                    <p>Could not deactivate: {error.message}</p>,
                    () => this.setState({working: false}),
                    () => false,
                    {rejectText: ''}
                );
            }
        );
    }

    reactivatePatient = () => {
        const { confirm } = this.context;
        const { patient, onChangePatient } = this.props;

        AuthStore.fetch(getConfig('users_api') + patient.links.activate.href, {method: 'POST'})
                 .then(
            success => {
                if (success.message === 'Patient reactivated') {
                    patient.active = 1;
                    patient.activated_date = moment().format();

                    confirm(<p>Successfully reactivated {patient.first_name}&apos;s account.</p>, () => false, () => false, {rejectText: ''});
                }

                if (success.message === 'Invitation sent') {
                    patient.invite_sent = moment().format('YYYY-MM-DD hh:mm:ss');
                    patient.invite_accepted = null;
                    patient.active = 1;

                    confirm(<p>{patient.first_name} has been reinvited to EatLove.</p>, () => false, () => false, {rejectText: ''});
                }

                onChangePatient(patient);
                this.setState({working: false});
            },
            error => {
                if (error && error.message === 'Patient limit reached') {
                    const { showUpgradeForm } = this.context;

                    showUpgradeForm({feature: 'create_patient'});
                    return;
                }

                confirm(
                    <p>Could not reactivate: {error.message}</p>,
                    () => this.setState({working: false}),
                    () => false,
                    {rejectText: ''}
                );
            }
        );
    }

    getDaysSinceActivated = (patient) => {
        return moment().diff(patient.activated_date, 'day');
    }

    startDeactivatePatient = () => {
        const { patient } = this.props;
        const { confirm } = this.context;

        const days = this.getDaysSinceActivated(patient);

        confirm(
            <div>
                <p>Are you super sure you want to deactivate this {patient.practice_type === 'dietetics' ? 'patient' : 'client'}?</p>
            </div>,
            this.deactivatePatient,
        );
    }

    startReactivatePatient = () => {
        const { patient } = this.props;
        const { confirm } = this.context;

        confirm(
            <div>
                <p>Are you super sure you want to re-activate this {patient.practice_type === 'dietetics' ? 'patient' : 'client'}?</p>
            </div>,
            this.reactivatePatient,
        );
    }

    render() {
        const { working } = this.state;
        const { patient } = this.props;
        const { practice = {}, capabilities = {} } = UserStore.getUser() || {};

        const days = this.getDaysSinceActivated(patient);
        const sinceActivated = moment().diff(patient.activated_date, 'second');


        if (patient.active) {
            if (capabilities.deactivate_patient_cooldown && sinceActivated < capabilities.deactivate_patient_cooldown) {
                const diffHours = Math.ceil((capabilities.deactivate_patient_cooldown - sinceActivated) / 3600);
                const diffMinutes = Math.ceil((capabilities.deactivate_patient_cooldown - sinceActivated) / 60);
                let diffAmount, diffUnit;

                if (diffHours > 1) {
                    diffAmount = diffHours;
                    diffUnit = 'hours';
                }

                if (diffMinutes > 1 && diffMinutes <= 60) {
                    diffAmount = diffMinutes;
                    diffUnit = 'minutes';
                }

                if (diffMinutes == 1) {
                    diffAmount = 1;
                    diffUnit = 'minute';
                }

                return (
                    <div className="patient-deactivator">
                        <p>{patient.first_name} can be deactivated in <em>{diffAmount} {diffUnit}</em></p>
                        <button className="inactive-deactivate-btn el-medium-btn">
                            deactivate
                        </button>
                    </div>
                );
            }

            return (
                <div className="patient-deactivator">
                    <p>To release this client seat, click the deactivate button below</p>

                    <button onClick={this.startDeactivatePatient} className="deactivate-btn el-medium-btn">
                        deactivate
                    </button>
                </div>
            );
        }

        return (
            <div className="patient-deactivator">
                {patient.deactivated_date ?
                    <p>Deactivated on {moment(patient.deactivated_date).format('MMM Do, YYYY')}</p>
                : null}

                <button onClick={this.startReactivatePatient} className="reactivate-btn el-medium-btn">
                    activate {patient.first_name}
                </button>
            </div>
        );
    }
}
