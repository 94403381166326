'use strict'

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { getExpirationInfo } from '../../../pro/utils/Patients';

export default class InviteOrComplete extends Component {
    static propTypes = {
        patient: PropTypes.object,
        sendPatientInvite: PropTypes.func,
        showEditMeta: PropTypes.func,
        sendInvite: PropTypes.bool,
        showViewProfile: PropTypes.bool,
        showExpiration: PropTypes.string,
    };

    static contextTypes = {
        location: PropTypes.object,
        router: PropTypes.object,
    };


    onSendPatientInvite = (event) => {
        const { patient, sendPatientInvite } = this.props;
        event.preventDefault();
        sendPatientInvite(patient);
    }

    onShowEditMeta = (event) => {
        const { patient, showEditMeta } = this.props;
        event.preventDefault();
        showEditMeta(patient);
    }


    reRoute = (event, url) => {
        const { router, location } = this.context;
        const {query, hash } = location;
        const {pathname, query: queryParams = {}} = url;

        event.preventDefault();
        router.push({pathname, query: Object.assign(query, queryParams), hash});
    }

    render = () => {
        const { patient, showSendPlan, showViewProfile, showExpiration, reactivatePatient, className, reactivateClassName } = this.props;
        const {  location } = this.context;
        const { completed = [] } = patient;
        const { query } = location

        const { days, expired, expiring_soon } = getExpirationInfo(patient.active_until, patient.expiration);

        let expirationText = '';

        if (showExpiration && expired) {
            expirationText = showExpiration === 'info_card' ? `Expired ${days + (days === 1 ? " Day" : " Days")} ago` : 'Renew';
        } else if (expiring_soon && days > 0) {
            expirationText = showExpiration === 'info_card' ? `Expiring in ${days + (days === 1 ? " Day" : " Days")}` : 'Extend';
        } else if (expiring_soon) {
            expirationText = showExpiration === 'info_card' ? `Expiring today` : 'Extend';
        } else if (days) {
            expirationText = days >= 30 ? `Active expiring on ${moment(patient.active_until).format("MM/DD/YYYY")}` : `Active expiring in ${days} days`;
        } else {
            expirationText = "Active: No expiration";
        }


        let expirationClassName = 'active-until';

        if (expired) {
            expirationClassName = 'renew-btn';
        } else if (expiring_soon) {
            expirationClassName= 'extend-btn';
        }

        if (!patient.active) {
            if (showViewProfile) {
                return (
                    <div>
                        {showExpiration === 'info_card' ? <span className="expiration-text" data-expiration={expirationClassName}> <i className="dot"/>{expirationText}</span> : null}
                        <button onClick={(e) => this.reRoute(e, {pathname: `/clients/${patient.uuid}}`})} className={className}>View Profile</button>

                    </div>
                );
            }

        if (reactivatePatient) {
            return (
                <span>
                    {patient.deactivated_date ?
                        <span className="active-until">
                            • Deactivated
                            <span>&nbsp;on {moment(patient.deactivated_date).format('MMM Do, YYYY')}</span>
                        </span>
                     : null}

                    <button
                        className={reactivateClassName || "reactivate-btn"}
                        onClick={reactivatePatient}
                    >
                        Reactivate
                    </button>
                </span>
            );
        }

            return <span />
        }

        if (patient.practice_type === 'fitness' && !patient.invite_accepted) {
            return (
                <div>
                    {showExpiration === 'info_card' ? <span className="expiration-text" data-expiration={expirationClassName}> <i className="dot"/>{expirationText}</span> : null}
                    <button onClick={(e) => this.onSendPatientInvite(e)} className={className}>
                        {patient.invite_sent ? 'resend invite' : 'send invite'}
                    </button>
                </div>
            );
        }

        if (showExpiration && expired) {
            return (
                <div>
                    <button onClick={(e) => this.onShowEditMeta(e)} className={expirationClassName}>{expirationText}</button>
                </div>
            );
        }
        const base = {pathname: '/clients', query: {...query, patientId: patient.uuid}};

        const nextSteps = {
            anthro: {
                url: {...base, query: {...base.query, page: 1}},
                copy: 'Enter Client Info'
            },
            taste:        {
                url: {...base, query: {...base.query, page: 2}},
                copy: 'Set Taste Preferences'
            },
            meals:        {
                url: {...base, query: {...base.query, page: 4}},
                copy: 'Set Meal Preferences'
            },
            conditions:   {
                url: {...base, query: {...base.query, page: 5}},
                copy: patient.practice_type === 'dietetics' ? 'Set Conditions' : 'Set Lifestyle',
            },
            energy:       {
                url: {...base, query: {...base.query, page: 6}},
                copy: 'Set Energy Needs'
            },
            nutrition: {
                url: {...base, query: {...base.query, page: 7}},
                copy: patient.practice_type === 'dietetics' ? 'Confirm Prescription' : 'Confirm Nutrition',
            },
        };

        const notCompleted = Object.keys(nextSteps).filter(s => !completed.includes(s));

        if (notCompleted.length > 0) {
            const nextStep = nextSteps[notCompleted[0]];

            return (
                <div>
                    {showExpiration === 'info_card' ? <span className="expiration-text" data-expiration={expirationClassName}> <i className="dot"/>{expirationText}</span> : null}
                    <button onClick={(e) => this.reRoute(e, nextStep.url)} className={className}>{nextStep.copy}</button>
                </div>
            );
        }

        if (patient.next_update && moment().add(1, 'week').isAfter(patient.next_update)) {
            const editConditionsUrl = {
                pathname: `/clients/${patient.uuid}/nutrition`,
                query: {editConditions: 1},
            };

            return (
                <div>
                    {showExpiration === 'info_card' ? <span className="expiration-text" data-expiration={expirationClassName}> <i className="dot"/>{expirationText}</span> : null}
                    <button  onClick={(e) => this.reRoute(e, editConditionsUrl)} className={className}>
                        Update {patient.practice_type === 'dietetics' ? 'Prescription' : 'Profile'}
                    </button>
                </div>
            );
        }

        if (!patient.invite_accepted) {
            return (
                <div>
                    {showExpiration === 'info_card' ? <span className="expiration-text" data-expiration={expirationClassName}> <i className="dot"/>{expirationText}</span> : null}
                    <button data-urgent={!patient.invite_sent} onClick={(e) => this.onSendPatientInvite(e)} className={className}>{patient.invite_sent ? "resend invite" : "send invite"}</button>
                </div>
            );
        }

        if (showExpiration && expiring_soon) {
            return (
                <div>
                    <button onClick={(e) => this.onShowEditMeta(e)} className={expirationClassName}>{expirationText}</button>
                </div>
            );
        }


        if (showSendPlan && patient.practice_type === 'dietetics') {
            return (
                <div>
                    {showExpiration === 'info_card' ? <span className="expiration-text" data-expiration={expirationClassName}> <i className="dot"/>{expirationText}</span> : null}
                    <button onClick={(e) => this.reRoute(e, {pathname: `/clients/${patient.uuid}/meals`})} className={className}>send meal plan</button>
                </div>
            );
        }

        //Active
        if (showExpiration === 'info_card' && !expired && !expiring_soon) {
            return (
                <div>
                    <span className={expirationClassName}><i className="dot"/>{expirationText}</span>
                </div>            
            );
        }

        return <span />;
    }
}
