'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Activity from './RecentActivity/Activity.react';

import { fetchDocumentsById } from '../../../utils/Content';
import { getAssetsFromActivities } from '../../utils/Patients';
import { getConfig } from '../../../utils/Env';

import TaxonomyStore from '../../../stores/TaxonomyStore';
import TaxonomyActions from '../../../actions/TaxonomyActions';
import AuthStore from '../../../stores/AuthStore';
import UserStore from '../../../stores/UserStore';

import './RecentActivity.scss';

export default class RecentActivity extends Component {
    static propTypes = {
    };

    static contextTypes = {
        isExpanderVisible: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            loaded: false,

            results: [],
            assets: {},
            keyIndex: TaxonomyStore.getKeyIndex(),
        };
    }

    componentDidMount = () => {
        this.loadRecentActivity();

        TaxonomyActions.ensureLoaded();
        TaxonomyStore.addChangeListener(this.onTaxonomyStoreChange);
    }

    componentWillUnmount = () => {
        TaxonomyStore.removeChangeListener(this.onTaxonomyStoreChange);
    }

    onTaxonomyStoreChange = () => {
        this.setState({keyIndex: TaxonomyStore.getKeyIndex()});
    }

    syncAssets = () => {
        const { results } = this.state;

        getAssetsFromActivities(results).then((assets) => {
            this.setState({assets, synced: true});
        });
    }

    loadRecentActivity = (append = false) => {
        const user = UserStore.getUser();
        const { results } = this.state;

        // Using my practice's activity resource, find this patients recent activity.
        const query = {
            following: 'current',
            size: 50,
        };

        if (append) {
            query.from = results.length;
        }

        this.setState({working: true});

        AuthStore.fetch({url: getConfig('users_api') + user.links.activity.href, query}).then(
            response => {
                const newResults = append ? results.concat(response.elements) : response.elements;

                this.setState({
                    results: newResults,
                    total: response.total,
                    working: false,
                }, this.syncAssets);
            },
            error => this.setState({working: false, error: error.message})
        );
    }

    showMore = () => {
        this.loadRecentActivity(true);
    }

    renderActivity = (activity, key) => {
        const { assets, keyIndex } = this.state;

        return (
            <Activity key={key} activity={activity} assets={assets} keyIndex={keyIndex} />
        );
    }

    render() {
        const { location } = this.context;
        const { working, total, results } = this.state;

        const user = UserStore.getUser();

        if (!user) {
            return <span />;
        }

        if (!working && total == 0 && results.length <= 0) {
            return (
                <div className="dashboard-recent-activity">
                    <h2>{user.practice_type === 'dietetics' ? 'Patient Activity' : 'Client Activity'}</h2>

                    <ul>
                        <li className="dashboard-activity">
                            <div className="thumb">
                                <i className="icon icon-logo2"><span className="path1" /><span className="path2" /></i>
                            </div>

                            <div className="container">
                                <h6>As soon as your {user.practice_type === 'dietetics' ? 'patients' : 'clients'} start using EatLove we'll show their daily activity. </h6>
                            </div>
                        </li>
                    </ul>
                </div>
            );
        }

        return (
            <div className="dashboard-recent-activity">
                <header>
                    <h2>{user.practice_type === 'dietetics' ? 'Patient Activity' : 'Client Activity'}</h2>
                    {user.practice && user.practice.statistics && user.practice.statistics.patient_activity_score >= 1000 ?
                        <h3><em>{user.practice.statistics.patient_activity_score.toLocaleString()}</em> Actions Taken</h3>
                    : null}
                </header>

                {results.length > 0 ?
                    <ul>
                        {results.map(this.renderActivity)}
                    </ul>
                : null}

                {working ?
                    <p className="loading-activity">
                        Loading recent activity <br />
                        <i className="icon-spinner" />
                    </p>
                : null}

                <footer>
                    {!working && results.length < total && results.length < 500 ?
                        <button className="show-more-btn" onClick={this.showMore}>show more</button>
                    : null}
                </footer>
            </div>
        );
    }
}
