'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import store from 'store';
import moment from 'moment';
import uuidGen from 'uuid';
import omit from 'lodash.omit';
import debounce from 'lodash.debounce';
import Helmet from 'react-helmet';

import RecipeDetailsModal from '../Search/Modals/RecipeDetailsModal.react';
import FoodDetailsModal from '../Search/Modals/FoodDetailsModal.react';
import AddSwapRecipe from '../Search/Modals/AddSwapRecipe.react';
import MealDraggable from './Editor/MealDraggable.react';
import FavoriteButton from '../Widgets/FavoriteButton.react';
import SharePopup from '../Widgets/SharePopup.react';
import CollectionToPdfButton from './Editor/CollectionToPdfButton.react';
import RecommendModal from '../../pro/components/Modals/RecommendModal.react';
import CollectionBanner from './Editor/CollectionBanner.react';

import UserStore from '../../stores/UserStore';
import BoardStore from '../../stores/BoardStore';
import BoardActions from '../../actions/BoardActions';
import AuthStore from '../../stores/AuthStore';

import Analytics from '../../utils/Analytics';

import { getConfig } from '../../utils/Env';
import { inquire } from '../../utils/Enforcer';
import { getParamsFromQuery, getQueryFromParams } from '../../utils/Search';

import { getAssetsForMeals, getPrimaryMeal } from '../../utils/Meals';
import { createNewDocument, fetchDocumentsById, updateCachedDocuments } from '../../utils/Content';

import './Editor.scss';

export default class CollectionEditor extends Component {

    static propTypes = {
        closeModal: PropTypes.func,
        saveDirtyBtnText: PropTypes.node,
        saveCleanBtnText: PropTypes.node,
    };

    static defaultProps = {
        saveDirtyBtnText: 'save recipe book',
        saveCleanBtnText: 'close',
    };

    static contextTypes = {
        location: PropTypes.object,
        router: PropTypes.object,

        isPro: PropTypes.bool,
    };

    static childContextTypes = {
        recipes: PropTypes.object,
        details: PropTypes.object,
        foods: PropTypes.object,
        merchants: PropTypes.object,
        brands: PropTypes.object,
        locations: PropTypes.object,
        addSwapContext: PropTypes.object,
        collection_editor_recipes: PropTypes.array,

        onModifyMeals: PropTypes.func,
        onSelectRecipe: PropTypes.func,
        onSelectFood: PropTypes.func,
        showMealDetails: PropTypes.func,
    };

    constructor(props, context) {
        super(props, context);

        const user = UserStore.getUser();

        this.state = {
            collection: null,
            user,

            profile: user,

            isSearchDirty: false,
            searchParams: {
                types: ['recipe'],
                filters: {},
                language: (user && user.language) || 'en',
                include_library: true,
                size: 12,
                terms: '',
                include_merchants: user?.features?.source_libraries || null
            },
        };

        this.resetLastSaved = debounce(this.resetLastSaved, 8000);
        this.syncAndAutosave = debounce(this.syncAndAutosave, 1500);
    }

    getChildContext = () => {
        const { assets = {} } = this.state;
        const { recipes = {}, details = {}, foods = {}, merchants = {}, brands = {}, locations = {} } = assets;

        return {
            recipes,
            details,
            foods,
            merchants,
            brands,
            locations,
            collection_editor_recipes: Object.keys(recipes),

            addSwapContext: {
                fullBrowserSearchPlaceholder: 'Search for a food or recipe',
            },

            onModifyMeals: this.onModifyMeals,
            onSelectRecipe: this.onSelectRecipe,
            onSelectFood: this.onSelectFood,
            showMealDetails: this.showMealDetails,
        };
    }

    componentDidMount = () => {
        this.loadCollection();
    }

    createEmptyCollection = async () => {
        const { location } = this.context;
        const { query = {} } = location;
        const { user } = this.state;

        const collection = {
            language: user.language,
            title: '',
            items: [],
            tags: [],
        };

        if (query.startWith) {
            const content = (await fetchDocumentsById([query.startWith]))[0];

            if (content && content.type === 'recipe') {
                collection.items.push({
                    id: this.generateMealId(),
                    recipe_uuid: content.uuid,
                    details_uuid: content.details,
                    meal_type: 'fresh',
                });
            }
        }

        this.setState({collection, previousItemsLength: 0}, this.syncAssets);
    }

    loadCollection = () => {
        const { uuid } = this.props;

        if (!uuid) {
            this.createEmptyCollection();

            return;
        }

        this.setState({loading: true});

        // First, inquire to see if we have write and publish permission.
        const inquiries = [
            {action: 'publish', resource: uuid},
            {action: 'write',   resource: uuid},
        ];

        inquire(inquiries).then(decisions => {
            if (!decisions) {
                this.setState({canWrite: false});
                return;
            }

            let canPublish = decisions.filter(d => d.action == 'publish')[0].decision === 'permit';
            let canWrite   = decisions.filter(d => d.action == 'write')[0].decision === 'permit';

            this.setState({canPublish, canWrite}, () => {
                this.loadCollectionData(uuid);
            });
        });
    }

    getBoardContentByUuid = (uuid) => {
        const boards = BoardStore.getBoardsByResourceId(uuid);
        const contents = boards.reduce((carry, board) => {
            const bcs = board.contents.filter(
                ({dietitian_recommendation, resource_id}) => dietitian_recommendation && resource_id === uuid
            );

            return carry.concat(bcs);
        }, []);

        return contents[0] ? contents[0] : null;
    }

    loadCollectionData = async (uuid) => {
        const { isPro } = this.context;
        const { canWrite } = this.state;
        let { searchParams, isSearchDirty } = this.state;

        const first = await fetchDocumentsById([uuid]);
        const collection = JSON.parse(JSON.stringify(first[0])); // deeeeep copy
        const previousItemsLength = collection.items.length;

        if (!(collection && collection.type == 'collection')) {
            this.setState({error: 'cannot edit, not a collection', collection: null});

            return;
        }

        if (!canWrite || collection.status === 'live') {
            if (isPro) {
                collection.title = collection.title + ' 2.0';
            } else {
                collection.title = 'My ' + collection.title;
            }
        }


        try {
            searchParams = getParamsFromQuery(JSON.parse(collection.filters));
            isSearchDirty = true;
        } catch (e) {
            // Do nothing
        }

        // Immediately analyze the collection, so we're for sure we're showing correct nutrition and allergen information.
        this.setState({collection, previousItemsLength, searchParams, isSearchDirty, loading: false}, this.syncAssets);

        const traits = {};

        const boardContent = this.getBoardContentByUuid(uuid);
        if (boardContent && boardContent.author_name) {
            traits['Recommended By'] = boardContent.author_name;
        }

        Analytics.openCollection(collection, traits);
    }

    syncAssets = async () => {
        const { collection } = this.state;

        if (!collection) {
            return this.setState({assets: {}});
        }

        const assets = await getAssetsForMeals(collection.items);

        this.setState({assets, synced: true});

        return assets;
    }

    syncAndAutosave = async () => {
        const { recipes } = await this.syncAssets();
        const { collection } = this.state;

        // Find the first item and set its image to the collection image, then save
        if (collection.items && collection.items[0] && collection.items[0].recipe_uuid &&
            recipes[collection.items[0].recipe_uuid]) {
            collection.image = recipes[collection.items[0].recipe_uuid].image;
        }

        this.setState({collection}, this.onSaveCollection);
    }

    closeModal = () => {
        const { closeModal } = this.props;

        closeModal();
    }

    generateMealId() {
        return uuidGen.v4().substring(0, 8);
    }

    resetLastSaved = () => {
        this.setState({lastSaved: null});
    }

    shouldOverwriteCollection = (collection) => {
        const { user, canWrite } = this.state;

        if (!canWrite) {
            return false;
        }

        // We never overwrite a live collection
        if (collection.status === 'live') {
            return false;
        }

        // the document must be saved new
        if (!collection.links || !collection.uuid) {
            return false;
        }

        // We only ever overwrite our own recipes
        if (collection.owner !== user.uuid) {
            return false;
        }

        return true;
    }

    overwriteCollection = async (collection) => {
        collection = await AuthStore.fetch(getConfig('recipe_api') + collection.links.self.href, {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=collection/1'},
            body: JSON.stringify(omit(collection, 'links', 'uuid', 'type', 'status')),
        });

        return collection;
    }

    storeNewCollection = async (collection) => {
        delete collection.owner;

        // Are we creating a copy from a new recipe? Ensure the parent_uuid is saved
        if (collection.uuid) {
            collection.parent_uuid = collection.uuid;
        }

        collection = await createNewDocument(
            'collection',
            omit(collection, 'links', 'uuid', 'type', 'details', 'status', 'owner'),
            'collection/1'
        );

        Analytics.saveNewCollection(collection);

        return collection;
    }

    validate = () => {
        const { collection } = this.state;

        collection.items = collection.items || [];

        // Save without a title if we have 2 or more items.
        if (collection.items.length >= 2) {
            return true;
        }

        collection.title = (collection.title || '').trim();

        if (!collection.title) {
            return false;
        }

        if (!collection.items.length) {
            return false;
        }

        return true;
    }

    addCollectionToFavorites = (collection) => {
        const lastBoard = BoardStore.getDefaultBoard();
        const newItem = {
            resource_id: collection.uuid,
            resource_type: collection.type,
        };

        if (lastBoard.links) {
            // Board already exists, just add to it.
            BoardActions.addToBoard(lastBoard, [newItem]);
        } else {
            lastBoard.contents = lastBoard.contents || [];
            lastBoard.contents.push(newItem);

            // Board does not exist, create a whole new one
            BoardActions.upsertBoards([lastBoard]);
        }
    }

    onSaveCollection = async () => {
        if (this.state.saving) {
            return;
        }

        if (!await this.validate()) {
            return;
        }

        const { onSaveCollection } = this.props;
        const { user, dirty, searchParams } = this.state;
        const { synchronizeAssets } = this.context;

        let { collection } = this.state;

        collection.filters = JSON.stringify(getQueryFromParams(searchParams));

        // If the collection is not dirty, do not store a new copy.
        if (!dirty) {
            onSaveCollection && onSaveCollection(collection);
            return;
        }

        if (!user) {
            this.setState({error: 'You must be logged in to save changes to this collection', saving: false});
            return false;
        }

        this.setState({saving: true, error: null});

        let newState = {
            lastSaved: moment(),
            saveError: false,
            saving: false,
            canCancel: false,
            dirty: false,
        };

        const backupKey = 'collection-edited-' + (collection.uuid ? collection.uuid : 'new')

        try {
            // Should I overwrite to this collection?
            if (this.shouldOverwriteCollection(collection)) {
                collection = await this.overwriteCollection(collection);

            } else {
                // Save a new copy of the collection
                collection = await this.storeNewCollection(collection);

                // of course we can save our own collection!
                newState.canWrite = true;

                // Save the new collection in our favorites, so we can find it later.
                this.addCollectionToFavorites(collection);
            }
        } catch (exp) {

            this.setState({error: (exp && exp.message) || exp || 'unknown error', saving: false});
            return;
        }

        // Remove the backup.
        store.remove(backupKey);

        updateCachedDocuments([collection]);
        synchronizeAssets && synchronizeAssets();

        this.setState({collection, backup: null, ...newState}, this.syncAssets);
        this.resetLastSaved();

        onSaveCollection && onSaveCollection(collection);
    }

    moveMeal = (item, afterItem) => {
        const { collection } = this.state;

        let sourceIndex = collection.items.map((element) => element.id).indexOf(item.id);
        let destIndex = collection.items.map((element) => element.id).indexOf(afterItem.id);

        if (sourceIndex == destIndex) {
            return;
        }

        if (sourceIndex != -1) {
            // Remove the item from its current spot
            collection.items.splice(sourceIndex, 1);
        }

        if (destIndex != -1) {
            // Add it to its new spot
            collection.items.splice(destIndex, 0, item);
        }

        this.setState({collection, dirty: true});
    }

    onModifyMeals = (meals) => {
        let { collection } = this.state;

        meals.forEach(meal => {
            const found = collection.items.find(i => i.id == meal.id);

            if (found) {
                const index = collection.items.indexOf(found);

                collection.items[index] = meal;
            }
        });

        this.setState({collection, dirty: true}, this.syncAndAutosave);
    }

    showMealDetails = (meals) => {
        const { router, location } = this.context;
        const { pathname, hash, query } = location;
        const { primary } = getPrimaryMeal(meals);

        if (!primary) {
            return;
        }

        query.meal = primary.id;

        router.push({pathname, hash, query});
    }

    startAddContent = () => {
        const { location, router } = this.context;
        const { pathname, hash, query } = location;

        query.add = 1;
        router.push({pathname, hash, query});
    }

    closeAddSwapModal = () => {
        const { router, location } = this.context;
        const { pathname, hash, query } = location;

        delete query.add;

        router.push({pathname, hash, query});
    }

    onSelectRecipe = (recipe) => {
        const { collection } = this.state;
        const previousItemsLength = collection.items.length;

        if (collection.items.find(item => item.recipe_uuid === recipe.uuid)) {
            this.closeAddSwapModal();
            return;
        }

        collection.items.push({
            id: this.generateMealId(),
            meal_type: 'fresh',
            recipe_uuid: recipe.uuid,
            details_uuid: recipe.details,
        });

        this.setState({collection, previousItemsLength, dirty: true}, this.syncAndAutosave);

        this.closeAddSwapModal();
    }

    onSelectFood = (food) => {
        const { collection } = this.state;
        const previousItemsLength = collection.items.length;

        if (collection.items.find(item => item.food_uuid === food.uuid)) {
            this.closeAddSwapModal();
            return;
        }

        collection.items.push({
            id: this.generateMealId(),
            meal_type: 'fresh',
            food_uuid: food.uuid,
        });

        this.setState({collection, previousItemsLength, dirty: true}, this.syncAndAutosave);

        this.closeAddSwapModal();
    }

    deleteMeal = (item) => {
        const { collection } = this.state;
        const previousItemsLength = collection.items.length;
        const index = collection.items.indexOf(item);

        if (index !== -1) {
            collection.items.splice(index, 1);
        }

        this.setState({collection, previousItemsLength, dirty: true}, this.syncAndAutosave);
    }

    closeDetailsModal = () => {
        const { location, router } = this.context;
        const { pathname, hash, query } = location;

        delete query.meal;

        router.push({pathname, hash, query});
    }

    onChangeTitle = (ev) => {
        const { collection } = this.state;

        collection.title = ev.target.value;

        this.setState({collection, dirty: true}, this.syncAndAutosave);
    }

    onStartRecommend = () => {
        const { collection } = this.state;
        const { location, router } = this.context;
        const { pathname, hash, query } = location;

        if (!collection.title) {
            this.setState({error: "Please enter a title before sharing"});
            return;
        }

        if (!collection.items.length) {
            this.setState({error: "Please add some recipe to the collection before sharing"});
            return;
        }

        query.recommend = 1;

        router.push({pathname, hash, query});
    }

    closeRecommendModal = (sent) => {
        const { location, router } = this.context;
        const { pathname, hash, query } = location;

        delete query.recommend;

        router.push({pathname, hash, query});
    }

    renderMealDetails = () => {
        const { location,isPro } = this.context;
        const { collection, profile } = this.state;
        const { meal } = location.query;

        if (!meal || !(collection && collection.items)) {
            return;
        }

        // Find the list of meals for this meal slot
        const item = collection.items.find(item => item.id == meal && !item.deleted);

        if (item && item.recipe_uuid && item.meal_type == 'fresh') {
            return <RecipeDetailsModal uuid={item.recipe_uuid}
                    closeModal={this.closeDetailsModal}
                    editableLeftovers={true}
                    editableParticipants={true}
                    hideScheduleButton={isPro} />
        }
        if (item && item.food_uuid && item.meal_type == 'fresh') {
            return  <FoodDetailsModal uuid={item.food_uuid}
                        closeModal={this.closeDetailsModal} />           
        }
    }

    onChangeSearchParams = (searchParams) => {
        this.setState({searchParams, isSearchDirty: true});
    }

    renderAddSwapModal = () => {
        const { location } = this.context;
        const { profile, modalSettings, searchParams, isSearchDirty } = this.state;
        const { add } = location.query;

        if (add) {
            return <AddSwapRecipe profile={profile}
                        closeModal={this.closeAddSwapModal}
                        onSelectRecipe={this.onSelectRecipe}
                        onSelectCombo={this.onSelectCombo}
                        onSelectFood={this.onSelectFood}
                        fullBrowserParams={{defaultParams: searchParams, inhibitSearchOnMount: isSearchDirty ? false : true}}
                        onChangeParams={this.onChangeSearchParams}
                        allowedTypes={['recipe', 'food']}
                        defaultMode="browser"
                        allowedModes={['favorites', 'browser', 'use-own-recipe']}
                        editableParticipants={false}
                        editableLeftovers={false}
                        modalTitle='Add to Collection' />
        }
    }

    renderCollectionItem = (meal, i) => {
        return (
            <li key={i}>
                <MealDraggable meal={meal}
                    onModifyMeals={this.onModifyMeals}
                    moveMeal={this.moveMeal}
                    deleteMeal={this.deleteMeal} />
            </li>
        );
    }

    renderCollectionForm = (collection) => {
        const { canWrite } = this.state;

        return (
            <div className="recipe-form collection-form el-list-form">
                <Helmet title={`${collection.title} | EatLove`} />

                {collection.uuid ? <CollectionBanner collection={collection} /> : null}

                {collection.uuid && !this.shouldOverwriteCollection(collection) ?
                    <div className="warning">You can edit this recipe collection. A personalized copy will be saved to your favorites.</div>
                : null}

                <div className="el-unlabeled-input collection-title">
                    <input type="text" value={collection.title} placeholder="Enter a title for your collection" onChange={this.onChangeTitle} />
                </div>

                {collection.uuid ? <FavoriteButton collection={collection} /> : null}

                {collection.uuid ? <SharePopup popupText={"To share, copy and paste this URL into an email or message."} dropdownBtnClass={"el-text-btn"} buttonText={""} collection={collection} /> : null}

                <ul className="item-list">
                    {collection && collection.items ?
                        collection.items.map(this.renderCollectionItem)
                    : null}

                    {(collection.items || []).length < 30 ?
                        <li>
                            <button className="add-meal-btn" onClick={this.startAddContent}><i className="icon-plus-thin" /></button>
                        </li>
                    : null}
                </ul>
            </div>
        );
    }

    renderRecommendModal = () => {
        const { collection, dirty, profile } = this.state;
        const { location } = this.context;
        const { patient } = this.props;

        if (!location.query.recommend) {
            return;
        }

        return <RecommendModal closeModal={this.closeRecommendModal}
                    patient={patient} collection={collection} />
    }

    renderFooterButtons = () => {
        const { collection, user, assets, synced } = this.state;
        const { isPro } = this.context;

        if (!(collection && collection.uuid) || !synced) {
            return;
        }

        const elements = [];

        if (!isPro) {
            elements.push(<button key="close" className="el-modal-cancel-btn" onClick={this.closeModal}>close</button>)
        }

        if (user && user.capabilities && user.capabilities.print_collection) {
            elements.push(<CollectionToPdfButton classes="el-modal-ok-btn" key="print" assets={assets} button={<span>PRINT</span>} collection={collection} />)
        }

        if (user && user.capabilities && user.capabilities.print_collection) {
            elements.push(<CollectionToPdfButton classes="el-modal-ok-btn" key="save" assets={assets} button={<span>SAVE PDF</span>} collection={collection} />)
        }

        if (isPro) {
            elements.push(<button key="recommend" className="el-modal-ok-btn" onClick={this.onStartRecommend}>recommend</button>)
        }

        if (elements.length > 0) {
            return <footer data-three={user && user.capabilities && user.capabilities.print_collection} data-pro={isPro}>{elements}</footer>
        }
    }

    render() {
        const { loading, collection, synced, assets, profile, error, saving, lastSaved, canCancel, dirty, previousItemsLength } = this.state;
        const { closeModal, saveDirtyBtnText, saveCleanBtnText, headerText } = this.props;

        let updateMessage = "Collection saved to Favorites"

        if (collection?.items?.length > previousItemsLength) {
            updateMessage = "Item has been added to the collection"
        }

        if (collection?.items?.length < previousItemsLength) {
            updateMessage = "Item has been removed from the collection"
        }

        return (
            <>
                <header>
                    <h2>{headerText}</h2>
                    <button className="el-modal-close-x" onClick={this.closeModal}>
                        <i className="icon-close-x" />
                    </button>
                    {!error && saving ? <p className="notice">Saving...</p> : null}
                    {!error && lastSaved ? <p className="notice">{updateMessage}</p> : null}
                    {error ? <p key="error" className="notice" data-error={true}>{error}</p> : null}
                </header>
                <div className="recipe-editor collection-editor el-modal-body-container el-modal2-body-container el-fonts">

                    {collection && synced ? this.renderCollectionForm(collection) : null}
                    {!synced ? <div className="loading"><p>loading...</p><i className="icon-spinner2" /></div> : null}

                    {this.renderAddSwapModal()}
                    {this.renderMealDetails()}
                    {this.renderRecommendModal()}
                </div>

                {this.renderFooterButtons(synced, collection)}
            </>
        );
    }
}
